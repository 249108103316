import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  actionTA,
  blockTradingAccountTrading,
  changeTaPassword,
  deleteTA,
  findTradingAccByUserMethod,
  getTradingAccBalance,
  getTradingAccStatuses,
  getTradingAccType,
  refreshApiKey,
} from 'api/tradingAcc';
import Button from 'components/ui/Button';
import TradingAccountsTable from 'components/ui/Table/TradingAccountsTable/TradingAccountsTable';
import TablePagination from 'components/ui/TablePagination';
import { checkMtStatus } from 'utils/checkMtStatusInfo';
import { getTablePageParams } from 'utils/getTablePageParams';
import { notify } from 'utils/notify';
import { usePermission } from 'utils/usePermission';
import styles from './tradingAccounts.module.scss';
import AddTradingAccountPopup from './AddTradingAccountPopup';
import TradingAccFilter from './TradingAccFilter/TradingAccFilter';
import { useTradingAccounts } from './useTradingAccounts';
import { FormProvider } from 'react-hook-form';
import { TradingAccFields } from './TradingAccFields/TradingAccFields';
import { TableLayout } from 'components/ui/Table/TableLayout';

interface ITableMeta {
  per_page: number;
  current_page: number;
  last_page: number;
}

interface ITableLinks {
  prev: null | string;
  next: null | string;
}

const TradingAccounts = ({ status, initialAllowDemo }) => {
  const { permissionGiven: PermissionCreateTradingAccount } = usePermission(
    'admin.trading-account.create',
  );
  const { permissionGiven: PermissionShowTradingAccount } = usePermission(
    'admin.trading-account.show',
  );

  const [demoAllowed, setDemoAllowed] = useState(initialAllowDemo);

  useEffect(() => {
    setDemoAllowed(initialAllowDemo);
  }, [initialAllowDemo]);

  const [tradingAccList, setTradingAccList] = useState([]);
  const [isListLoading, setIsListLoading] = useState(true);
  const [accountTypeOpt, setAccountTypeOpt] = useState([]);
  const [taStatusesOpt, setTaStatusesOpt] = useState([]);
  const [filters, setFilters] = useState(null);
  const [queryMessage, setQueryMessage] = useState('');
  const [tableMeta, setTableMeta] = useState<ITableMeta>();
  const [tableLinks, setTableLinks] = useState<ITableLinks>();
  const [perPageCount, setPerPageCount] = useState(10);

  const { id } = useParams();

  const componentMounted = useRef(true);

  const fetchTradingAccList = useCallback(
    (options, data) => {
      setIsListLoading(true);

      findTradingAccByUserMethod(getTablePageParams(options, perPageCount, tableLinks), data, id)
        .then((res) => {
          if (res.status === 200) {
            setTradingAccList(res.data.data);
            setTableMeta(res.data.meta);
            setTableLinks(res.data.links);
            setQueryMessage(res.data.message);
          }
        })
        .catch((error) => {
          if (error && error.response.data !== null) {
            notify({
              type: 'error',
              message: error.response,
              timeOut: 3000,
            });
          }
        })
        .finally(() => {
          setIsListLoading(false);
        });
    },
    [id, perPageCount],
  );

  const deleteTradingAccount = (ta_id) => {
    setIsListLoading(true);

    deleteTA(ta_id)
      .then((res) => {
        if (res.status === 202) {
          notify({
            type: 'success',
            message: res.data.message,
            timeOut: 3000,
          });
          fetchTradingAccList(null, null);
        }
      })
      .catch((error) => {
        if (error && error.response.data !== null) {
          notify({
            type: 'error',
            message: error.response,
            timeOut: 3000,
          });
        }
      })
      .finally(() => {
        setIsListLoading(false);
      });
  };

  const actionTaMethod = (data) => {
    setIsListLoading(true);
    const postData = {
      user_id: id,
      ta_id: data.taId,
      blocked: data.blocked,
    };

    actionTA(postData)
      .then((res) => {
        if (res.status === 202) {
          fetchTradingAccList(null, null);
          notify({
            type: 'success',
            message: res.data.message,
            timeOut: 3000,
          });
        }
      })
      .catch((error) => {
        if (error && error.response.data !== null) {
          notify({
            type: 'error',
            message: error.response,
            timeOut: 3000,
          });
        }
      })
      .finally(() => {
        setIsListLoading(false);
      });
  };

  const blockTaTradingMethod = (id: string, value: boolean) => {
    setIsListLoading(true);

    blockTradingAccountTrading(id, value)
      .then((res) => {
        if (res.status === 202) {
          fetchTradingAccList(null, null);
          notify({
            type: 'success',
            message: res.data.message,
            timeOut: 3000,
          });
        }
      })
      .catch((error) => {
        if (error && error.response.data !== null) {
          notify({
            type: 'error',
            message: error.response,
            timeOut: 3000,
          });
        }
      })
      .finally(() => {
        setIsListLoading(false);
      });
  };

  const changeTaPasswordMethod = (taId: string, data: any) => {
    setIsListLoading(true);

    const postData = {
      password: data.password,
      password_confirmation: data.password_confirmation,
    };

    changeTaPassword(taId, postData)
      .then((res) => {
        if (res.status === 200) {
          fetchTradingAccList(null, null);
          notify({
            type: 'success',
            message: res.data.message,
            timeOut: 3000,
          });
        }
      })
      .catch((error) => {
        if (error && error.response.data !== null) {
          notify({
            type: 'error',
            message: error.response,
            timeOut: 3000,
          });
        }
      })
      .finally(() => {
        setIsListLoading(false);
      });
  };

  const fetchTradingAccTypeList = async (): Promise<void> => {
    setIsListLoading(true);

    return getTradingAccType()
      .then((res) => {
        if (res.status === 200) {
          setAccountTypeOpt(
            res.data.data.map((el) => ({
              value: el,
              label: el,
            })),
          );
        }
      })
      .catch((error) => {
        if (error && error.response.data !== null) {
          notify({
            type: 'error',
            message: error.response,
            timeOut: 3000,
          });
        }
      })
      .finally(() => {
        setIsListLoading(false);
      });
  };

  const fetchTAStatusesOpt = () => {
    getTradingAccStatuses()
      .then((res) => {
        if (res.status === 200) {
          setTaStatusesOpt(
            res.data.data.map((item) => ({
              value: item,
              label: item,
            })),
          );
        }
      })
      .catch((error) => {
        if (error && error.response.data !== null) {
          notify({
            type: 'error',
            message: error.response,
            timeOut: 3000,
          });
        }
      });
  };

  useEffect(() => {
    const fetchTradingData = async () => {
      await fetchTradingAccTypeList();
      fetchTradingAccList(null, null);
      fetchTAStatusesOpt();
    };

    fetchTradingData();

    return () => {
      componentMounted.current = false;
    };
  }, [fetchTradingAccList, status]);

  const refreshApiToken = (taId) => {
    setIsListLoading(true);
    refreshApiKey(id, taId)
      .then((res) => {
        if (res.status === 202) {
          notify({
            type: 'success',
            message: res.data.message,
            timeOut: 3000,
          });
          fetchTradingAccList(null, null);
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
      })
      .finally(() => setIsListLoading(false));
  };

  const getBalance = (tradAcc, id) => {
    getTradingAccBalance(tradAcc)
      .then((data) => {
        setTradingAccList(
          tradingAccList.map((item) => {
            if (item.trading_account === id) {
              return (item.balance = data.data.balance);
            } else return item;
          }),
        );
        fetchTradingAccList(null, null);
      })
      .catch((err) => {
        checkMtStatus();
        notify({
          type: 'error',
          message: err.message,
          timeOut: 2000,
        });
      });
  };

  const onSubmit = (data) => {
    setFilters(data);
    fetchTradingAccList({ navigate: 'first', perPage: data?.filter?.limit }, data);
  };

  const { form, table } = useTradingAccounts();

  return (
    <>
      <div className={styles.tabTitle}>
        Trading Accounts{' '}
        {PermissionCreateTradingAccount ? (
          <AddTradingAccountPopup
            updateComponent={() => fetchTradingAccList(null, null)}
            userId={id}
            triggerBtn={
              <Button buttonText="+ Add trading account" buttonType="outline" onClick={null} />
            }
          />
        ) : null}
      </div>
      <FormProvider {...form}>
        <TradingAccFields
          userId={id}
          taStatusesOpt={taStatusesOpt}
          initialAllowDemo={initialAllowDemo}
          isListLoading={isListLoading}
        />
        <TableLayout
          header={
            <TradingAccFilter
              onSubmit={onSubmit}
              reloadFilters={isListLoading}
              queryMessage={queryMessage}
              setDefaultColumnsOrder={table.setDefaultColumnsOrder}
            />
          }
        >
          {PermissionShowTradingAccount ? (
            <TradingAccountsTable
              data={tradingAccList}
              perPage={tableMeta?.per_page}
              showLoader={isListLoading}
              userId={id}
              columns={table.columns}
              saveColumnOrder={table.saveColumnOrder}
              getBalance={(tradAcc, id) => getBalance(tradAcc, id)}
              // refreshApiToken={(taId) => refreshApiToken(taId)}
              actionTaMethod={(type) => actionTaMethod(type)}
              blockTaTradingMethod={(id, value) => blockTaTradingMethod(id, value)}
              deleteTradingAccount={(ta_id) => deleteTradingAccount(ta_id)}
              updateComponent={() => fetchTradingAccList(null, null)}
              changeTaPasswordMethod={changeTaPasswordMethod}
            />
          ) : null}
        </TableLayout>
        {PermissionShowTradingAccount && tradingAccList?.length ? (
          <TablePagination
            hidePageSelect={true}
            currentPage={tableMeta?.current_page}
            pagesLength={tableMeta?.last_page}
            perPageChange={(value) => {
              setPerPageCount(value);
              fetchTradingAccList({ perPage: value }, null);
            }}
            goToSelectedPage={(page) => {
              fetchTradingAccList({ page }, null);
            }}
          />
        ) : null}
      </FormProvider>
    </>
  );
};

export default TradingAccounts;
