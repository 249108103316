import useDynamicSearchPlaceholder from 'utils/useDynamicSearchPlaceholder';
import { useSearchCategoriesOptions } from 'utils/useGetSearchCategoriesOptions';
import { useFormContext } from 'react-hook-form';
import Button from 'components/ui/Button/Button';
import { isFormChange } from 'utils/isFormChange';
import { ManagementPartnersFilter } from 'models/Management/Partners/Filter';
import { ReloadButton } from 'components/ui/ReloadButton';
import { SearchBar } from 'components/ui/SearchBar';
import { managementPartnersDefaultValues } from '../usePartnersPage';
import styles from './parnters-filter.module.scss';

type Props = {
  onSubmit: (data: ManagementPartnersFilter) => void;
  isLoading: boolean;
  queryMessage: string;
  setDefaultColumnsOrder: () => void;
};

const PartnersFilter = (props: Props) => {
  const { onSubmit, isLoading, queryMessage, setDefaultColumnsOrder } = props;

  const partnersSearchOptions = useSearchCategoriesOptions('partner');

  const {
    handleSubmit,
    reset,
    watch,
    getValues,
    control,
    formState: { dirtyFields },
  } = useFormContext();

  const dynamicSearchPlaceholder = useDynamicSearchPlaceholder(
    watch,
    getValues,
    partnersSearchOptions,
  );

  const prepareFiltersData = (data) => {
    const postData = {
      filter: {
        country_ids: getValue(data['country_ids']),
        created_at: { from: data['created_at'].from, to: data['created_at'].to },
        statuses: getValue(data['statuses']) && [getValue(data['statuses'])],
      },
    };

    if (data['query']) {
      postData['query'] = data['query'];
    }

    if (data['query_category']) {
      postData['searchCategory'] = data['query_category']?.value;
    }

    return postData;
  };

  const submit = (data) => {
    const prepeared = prepareFiltersData(data);
    const postData = {
      ...prepeared,
      filter: clearEmptyFilter(prepeared.filter),
    };
    onSubmit(postData);
  };

  const resetForm = () => {
    reset(managementPartnersDefaultValues);
  };

  const getValue = (data) => {
    if (!data || (Array.isArray(data) && !data.length)) {
      return;
    }

    if (Array.isArray(data) && data.length) {
      const temp = data.map((item) => (item.value !== 'any' ? item.value : null));
      return temp.filter((item) => item || typeof item === 'number');
    }

    return data.value !== 'any' ? data.value : null;
  };

  const clearEmptyFilter = (data) => {
    const filtered = {};

    for (const key of Object.keys(data)) {
      if (data[key]) {
        filtered[key] = data[key];
      }
    }

    return filtered;
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(submit);
      }}
      className={styles.form}
    >
      <Button buttonText="Reset column order" onClick={setDefaultColumnsOrder} />
      <SearchBar
        className={styles.searchBar}
        queryMessage={queryMessage}
        inputProps={{
          id: 'query',
          name: 'query',
          control,
          placeholder: dynamicSearchPlaceholder,
          onSearch: () => submit(getValues()),
          onChange: (v) => !v?.length && submit(getValues()),
        }}
        selectProps={{
          id: 'query_category',
          name: 'query_category',
          control,
          options: partnersSearchOptions,
          onSelectChange: () => {
            const { query } = getValues();
            if (query) submit(getValues());
          },
        }}
      />
      <div className={styles.buttons}>
        <ReloadButton isLoading={isLoading} onClick={() => submit(getValues())} />
        <Button
          buttonText="Reset"
          buttonType="outline"
          isLoading={isLoading}
          type="reset"
          onClick={() => resetForm()}
          disabled={!isFormChange(dirtyFields)}
        />
        <Button
          buttonText="Apply"
          isLoading={isLoading}
          type="submit"
          onClick={handleSubmit(submit)}
          disabled={!isFormChange(dirtyFields)}
        />
      </div>
    </form>
  );
};

export default PartnersFilter;
