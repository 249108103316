import API from '../api';

export const getTradingAccList = (params, id) =>
  API.get(`/api/v1/trading-account/user/${id}?${params}`);

export const createTradingAcc = (data) => API.post('/api/v1/trading-account', data);

export const findTradingAccMethod = (params, data) =>
  API.post(`/api/v1/search/trading-accounts?${params}`, data);

export const getTradingAccStatuses = () => API.get('/api/v1/dictionary/ta-statuses');

export const getTradingAccPassword = (tradAcc) => API.get(`/api/v1/trading-account/password/${tradAcc}`);

export const getTradingAccBalance = (tradAcc) => API.get(`/api/v1/trading-account/balance/${tradAcc}`);

export const findTradingAccByUserMethod = (params, data, id) => (
    API.post(`/api/v1/search/trading-accounts/user/${id}?${params}`, data)
)
export const getTradingAccTypes = () => API.get('/api/v1/dictionary/ta-types');

export const getTradingAccWithCountList = (params: number = 100, id) =>
  API.get(`/api/v1/trading-account/user/${id}?${params}`);

export const refreshApiKey = (userId, taId) => (
    API.put(`/api/v1/trading-account/${taId}/user/${userId}/web-api-token/refresh`)
)

export const getTradingAccType = () => (
    API.get(`/api/v1/dictionary/ta-types`)
)

export const actionTA = (data) => (
    API.post(`/api/v1/trading-account/${data.ta_id}/blocked`, data)
)

export const deleteTA = (id) => (
  API.delete(`/api/v1/trading-account/${id}`)
)

export const changeTaPassword = (tradingAccount, data) => (
    API.post(`/api/v1/trading-account/${tradingAccount}/update-password`, data)
)
export const getTradingAccPaginatedList = (params: number = 100, id) =>
  API.get(`/api/v1/trading-account/user/${id}?${params}`);

export const blockTradingAccountTrading = (id: string, blocked: boolean) => 
  API.post(`/api/v1/trading-account/${id}/blocked-trading`, { blocked: blocked ? 1 : 0 })

export type AvailableForAccountWithdraw = {
  available_balance_amount: number,
  available_credit_amount: number
}

export const getAvailableAccountWithdrawal = (login: string) =>
  API.get<AvailableForAccountWithdraw>(`/api/v1/trading-account/available-for-withdrawal/${login}`)
