import { getCountryList } from 'api/country';
import React, { useEffect, useRef, useState } from 'react';
import { findOfficesMethod } from 'api/managements/offices/index';
import OfficesTable from 'components/ui/Table/OfficesTable';
import TablePagination from 'components/ui/TablePagination';
import { usePermission } from 'utils/usePermission.tsx';
import Page from '../../Page';
import { DashboardLayout } from '../../UserView/DashboardLayout';
import OfficesFilter from './Filter';

import { notify } from 'utils/notify.tsx';
import { getTablePageParams } from 'utils/getTablePageParams.js';
import { TableLinks, TableMeta } from 'models/Table.js';
import { CountryListValue } from 'models/Countries.js';
import { HierarchyOffice } from 'models/Hierarchy/HierarchyData.js';
import { ManagementOfficesFilter } from 'models/Management/Offices/Filter.js';
import { useAppDispatch } from 'store';
import { usePageTitle } from 'utils/usePageTitle';
import { OfficesPageTitle } from './OfficesPageTitle';
import { toggleScrollToTop } from 'store/slice/tableSlice';
import { useOfficesPage } from './useOfficesPage';
import { TableLayout } from 'components/ui/Table/TableLayout';
import { FormProvider } from 'react-hook-form';
import styles from './offices-page.module.scss';
import { OfficesPageFields } from './Fields/OfficesPageFields';

const title = 'Offices';

const OfficesPage = () => {
  const { permissionGiven: PermissionList } = usePermission('admin.office.index');

  const dispatch = useAppDispatch();
  const [rules, setRules] = useState<HierarchyOffice[]>([]);
  const [isListLoading, setIsListLoading] = useState(true);
  const [countryOpt, setCountryOpt] = useState<CountryListValue[]>([]);
  const [filters, setFilters] = useState<ManagementOfficesFilter>(null);
  const [queryMessage, setQueryMessage] = useState('');
  const [tableMeta, setTableMeta] = useState<TableMeta>();
  const [tableLinks, setTableLinks] = useState<TableLinks>();
  const [perPageCount, setPerPageCount] = useState(10);

  const componentMounted = useRef(false);

  usePageTitle(title);

  useEffect(() => {
    if (PermissionList) {
      componentMounted.current = true;
      getOffices();
    }
    return () => {
      componentMounted.current = false;
    };
  }, [PermissionList]);

  useEffect(() => {
    fetchCountryList();
  }, []);

  const fetchCountryList = () => {
    getCountryList()
      .then((res) => {
        if (res.status === 200) {
          const options = res.data.data.map((opt) => ({
            value: opt.id,
            label: opt.name,
            iso_3166_2: opt.iso_3166_2,
          }));
          setCountryOpt(options);
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
      });
  };

  const getOffices = (options?, data?) => {
    setIsListLoading(true);

    findOfficesMethod(getTablePageParams(options, perPageCount, tableLinks), data || filters)
      .then((res) => {
        if (componentMounted.current) {
          setRules(res.data.data);
          setTableMeta(res.data.meta);
          setTableLinks(res.data.links);
          setPerPageCount(res.data.meta.per_page);
          setQueryMessage(res?.data?.message || '');
        }
      })
      .catch(console.log)
      .finally(() => setIsListLoading(false));
  };

  const { form, table } = useOfficesPage();

  return (
    <DashboardLayout>
      <Page
        isStickyTitle
        title={
          <OfficesPageTitle
            total={tableMeta?.total}
            countries={countryOpt}
            updateComponentCallback={getOffices}
          />
        }
      >
        <>
          <FormProvider {...form}>
            <OfficesPageFields countries={countryOpt} />
            <TableLayout
              header={
                <OfficesFilter
                  onSubmit={(data) => {
                    setFilters(data);
                    getOffices({ navigate: 'first', perPage: data?.filter?.limit }, data);
                  }}
                  isLoading={isListLoading}
                  queryMessage={queryMessage}
                  setDefaultColumnsOrder={table.setDefaultColumnsOrder}
                />
              }
            >
              {PermissionList ? (
                <OfficesTable
                  data={rules}
                  countryList={countryOpt}
                  perPage={rules?.length}
                  showLoader={isListLoading}
                  modifyDataHandler={() => getOffices()}
                  columns={table.columns}
                  saveColumnOrder={table.saveColumnOrder}
                />
              ) : null}
            </TableLayout>
          </FormProvider>
          {rules?.length ? (
            <TablePagination
              currentPage={tableMeta?.current_page}
              pagesLength={tableMeta?.last_page}
              perPageChange={(value) => {
                setPerPageCount(value);
                getOffices({ perPage: value });
                dispatch(toggleScrollToTop());
              }}
              goToSelectedPage={(page) => {
                getOffices({ page });
                dispatch(toggleScrollToTop());
              }}
            />
          ) : null}
        </>
      </Page>
    </DashboardLayout>
  );
};

export default OfficesPage;
