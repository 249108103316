import React from 'react';
import { CirclePicker } from 'react-color';
import { useController } from 'react-hook-form';
import { DEFAULT_COLORS } from 'constants/colors';

export const ColorPickerCircle = (props) => {
  const { field } = useController(props);

  const handleChange = (value) => {
    field.onChange(value.hex);
  };

  return (
    <div>
      <CirclePicker color={props?.selected_color} colors={DEFAULT_COLORS} onChange={handleChange} />
    </div>
  );
};
