import React, { useEffect, useRef, useState } from 'react';
import { findDesksMethod, getDeskTypeMethod } from 'api/managements/desks/index';
import DesksTable from 'components/ui/Table/DesksTable';
import TablePagination from 'components/ui/TablePagination';
import { firstLetterUppercase } from 'utils/firstLetterUppercase.js';
import { usePermission } from 'utils/usePermission.tsx';
import Page from '../../Page';
import { DashboardLayout } from '../../UserView/DashboardLayout';

import DesksFilter from './Filter';
import { notify } from 'utils/notify.tsx';
import { getTablePageParams } from 'utils/getTablePageParams.js';
import { TableLinks, TableMeta } from 'models/Table.js';
import { HierarchyDesk } from 'models/Hierarchy/HierarchyData.js';
import { ManagementDesksFilter } from 'models/Management/Desks/Filter.js';
import { ListValue } from 'models/ListValue';
import { useAppDispatch } from 'store';
import { usePageTitle } from 'utils/usePageTitle';
import { toggleScrollToTop } from 'store/slice/tableSlice';
import { useDesksPage } from './useDesksPage';
import { DesksPageTitle } from './DesksPageTitle';
import { TableLayout } from 'components/ui/Table/TableLayout';
import { FormProvider } from 'react-hook-form';
import { DesksPageFields } from './Fields/DesksPageFields';

const title = 'Desks';

const DesksPage = () => {
  const { permissionGiven: PermissionListDesk } = usePermission('admin.desk.index');

  const dispatch = useAppDispatch();
  const [desks, setDesks] = useState<HierarchyDesk[]>([]);
  const [isListLoading, setIsListLoading] = useState(true);
  const [deskTypeList, setDeskTypeList] = useState<ListValue[]>([]);
  const [filters, setFilters] = useState<ManagementDesksFilter>(null);
  const [tableMeta, setTableMeta] = useState<TableMeta>();
  const [tableLinks, setTableLinks] = useState<TableLinks>();
  const [perPageCount, setPerPageCount] = useState(10);
  const [queryMessage, setQueryMessage] = useState('');

  const componentMounted = useRef(false);

  usePageTitle(title);

  useEffect(() => {
    if (PermissionListDesk) {
      componentMounted.current = true;
      getDesks();
    }
    return () => {
      componentMounted.current = false;
    };
  }, [PermissionListDesk]);

  useEffect(() => {
    getDeskTypeList();
  }, []);

  const getDeskTypeList = () => {
    getDeskTypeMethod()
      .then((res) => {
        if (res.status === 200) {
          const list = res.data.desk_types.map((item) => {
            return { value: item, label: firstLetterUppercase(item) };
          });
          setDeskTypeList(list);
        }
      })
      .catch((e) => {
        console.log(e.message);
      });
  };

  const getDesks = (options?, data?) => {
    setIsListLoading(true);

    findDesksMethod(getTablePageParams(options, perPageCount, tableLinks), data || filters)
      .then((res) => {
        if (componentMounted.current) {
          setDesks(res.data.data);
          setTableMeta(res.data.meta);
          setTableLinks(res.data.links);
          setPerPageCount(res.data.meta.per_page);
          setQueryMessage(res.data.message);
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
      })
      .finally(() => {
        setIsListLoading(false);
      });
  };

  const { form, table } = useDesksPage();

  return (
    <DashboardLayout>
      <Page
        isStickyTitle
        title={
          <DesksPageTitle
            total={tableMeta?.total}
            deskTypes={deskTypeList}
            updateComponentCallback={getDesks}
          />
        }
      >
        <>
          <FormProvider {...form}>
            <DesksPageFields deskTypes={deskTypeList} />
            <TableLayout
              header={
                <DesksFilter
                  onSubmit={(data) => {
                    setFilters(data);
                    getDesks({ navigate: 'first', perPage: data?.filter?.limit }, data);
                  }}
                  isLoading={isListLoading}
                  queryMessage={queryMessage}
                  setDefaultColumnsOrder={table.setDefaultColumnsOrder}
                />
              }
            >
              {PermissionListDesk ? (
                <DesksTable
                  data={desks}
                  deskTypeList={deskTypeList}
                  perPage={desks?.length}
                  showLoader={isListLoading}
                  modifyDataHandler={getDesks}
                  columns={table.columns}
                  saveColumnOrder={table.saveColumnOrder}
                />
              ) : null}
            </TableLayout>
          </FormProvider>
          {desks?.length ? (
            <TablePagination
              currentPage={tableMeta?.current_page}
              pagesLength={tableMeta?.last_page}
              perPageChange={(value) => {
                setPerPageCount(value);
                getDesks({ perPage: value });
                dispatch(toggleScrollToTop());
              }}
              goToSelectedPage={(page) => {
                getDesks({ page });
                dispatch(toggleScrollToTop());
              }}
            />
          ) : null}
        </>
      </Page>
    </DashboardLayout>
  );
};

export default DesksPage;
