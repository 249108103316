import { ResetColorsButton } from './components/ResetColorsButton';
import { ColorsForm } from './components/ColorsForm';
import { SetColorsForm } from './components/SetColorsForm';

import { Col, Row } from 'react-bootstrap';

type Props = {
  data: any;
  updateComponent: () => void;
};

export const ColorsTab = ({ data, updateComponent }: Props) => {
  return (
    <div>
      <Row>
        <Col md={6}>
          <ColorsForm data={data} updateComponent={updateComponent} />
        </Col>
        <Col md={6}>
          <SetColorsForm data={data} updateComponent={updateComponent} />
        </Col>
      </Row>
      <ResetColorsButton updateComponent={updateComponent} />
    </div>
  );
};
