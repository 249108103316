import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';

import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import FormInput from 'components/ui/FormInput';
import Button from 'components/ui/Button';
import styles from './../client-page.module.scss';
import { updatePersonalClientInfo } from 'api/profile';
import { notify } from 'utils/notify';
import { usePermission } from 'utils/usePermission';
import { Client } from 'models/Clients/Clients';
import { ICountry } from 'models/Countries';
import { Language } from 'models/Languages';
import { ListValue } from 'models/ListValue';
import JokerSelect from 'components/ui/JokerSelect/JokerSelect';

const cx = classNames.bind(styles);

type Props = {
  formData: Client;
  countryList: ICountry[];
  timeZone: ListValue[];
  langList: Language[];
  updateComponent: () => void;
};

const AdressForm = (props: Props) => {
  const { formData, countryList, timeZone, langList, updateComponent } = props;

  const [isLoading, setIsLoading] = useState(true);

  const { permissionGiven: PermissionEditAddress } = usePermission(
    'admin.client.edit-address-info',
  );

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    reValidateMode: 'onChange',
  });

  const onSubmit = (data) => {
    setIsLoading(true);
    const sendData = {
      ...data,
      country_id: data.country.id || null,
    };
    updatePersonalClientInfo(formData.user_profile.id, sendData)
      .then((res) => {
        if (res) {
          notify({
            type: 'success',
            message: 'Address updated successfully',
            timeOut: 3000,
          });
          updateComponent();
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (Object.keys(formData).length !== 0) {
      const country =
        countryList &&
        countryList.find((item) => item?.country_code === formData?.country?.country_code);

      setIsLoading(false);
      reset({
        country: { label: country?.name, value: country?.id },
        city: formData.user_profile.city,
        po_box: formData.user_profile.po_box,
        postal_code: formData.user_profile.postal_code,
        address: formData.user_profile.address,
      });
    }
  }, [countryList, formData, timeZone, langList]);

  return (
    <form className={cx('form')} onSubmit={handleSubmit(onSubmit)}>
      <div className={isLoading ? cx('p-0', 'blur') : cx('p-0')}>
        <div className={styles.tabTitle}>
          Address
          {PermissionEditAddress ? isDirty && <Button buttonText="Save changes" /> : null}
        </div>
        <Row>
          <Col md={6} lg={4} xs={12} className="mb-3">
            <JokerSelect
              id="country"
              name="country"
              label="Country"
              control={control}
              options={countryList.map((v) => ({ value: v.id, label: v.name }))}
            />
          </Col>
          <Col md={6} lg={4} xs={12} className="mb-3">
            <FormInput name="city" type="text" label="City" control={control} />
          </Col>
          <Col md={6} lg={4} xs={12} className="mb-3">
            <FormInput name="po_box" type="text" label="PO Box" control={control} />
          </Col>
          <Col md={6} lg={4} xs={12} className="mb-3">
            <FormInput name="postal_code" type="text" label="Post code" control={control} />
          </Col>
          <Col md={12} lg={8} xs={12} className="mb-3">
            <FormInput name="address" type="text" label="Full address" control={control} />
          </Col>
        </Row>
      </div>
    </form>
  );
};

export default AdressForm;
