import Button from 'components/ui/Button/Button';
import { LeadsFilter } from 'models/Leads/LeadsFilter';
import { useFormContext } from 'react-hook-form';
import useDynamicSearchPlaceholder from 'utils/useDynamicSearchPlaceholder';
import { useSearchCategoriesOptions } from 'utils/useGetSearchCategoriesOptions';
import { clearEmptyFilter } from 'utils/clearEmptyFilter';
import { isFormChange } from 'utils/isFormChange';
import { ReloadButton } from 'components/ui/ReloadButton';
import { SearchBar } from 'components/ui/SearchBar';
import styles from './lead-filter.module.scss';

type Props = {
  onSubmit: (data: LeadsFilter, hasReindex?: boolean) => void;
  isLoading: boolean;
  queryMessage: string;
  setDefaultColumnsOrder: () => void;
};

const LeadFilter = (props: Props) => {
  const { onSubmit, isLoading, queryMessage, setDefaultColumnsOrder } = props;

  const leadsSearchOptions = useSearchCategoriesOptions('lead');

  const {
    handleSubmit,
    reset,
    getValues,
    control,
    formState: { dirtyFields },
    watch,
  } = useFormContext();

  const dynamicSearchPlaceholder = useDynamicSearchPlaceholder(
    watch,
    getValues,
    leadsSearchOptions,
  );

  const prepareFiltersData = (data: any) => {
    const postData = {
      filter: {
        languages: getValue(data['languages']),
        countries: getValue(data['countries']),
        account_statuses: getValue(data['account_statuses']),
        created_at: data['created_at'],
        note_last: data['note_last'],
      },
    };

    if (data['query']) {
      postData['query'] = data['query'];
    }

    if (data['query_category']) {
      postData['searchCategory'] = data['query_category']?.value;
    }

    return postData;
  };

  const submit = (data, hasReindex = false) => {
    const prepeared = prepareFiltersData(data);
    const postData = {
      ...prepeared,
      filter: clearEmptyFilter(prepeared.filter),
    };
    onSubmit(postData, hasReindex);
  };

  const resetForm = () => {
    onSubmit(null);
    reset();
  };

  const getValue = (data) => {
    if (!data || (Array.isArray(data) && !data.length)) {
      return;
    }

    if (Array.isArray(data) && data.length) {
      const temp = data.map((item) => (item.value !== 'any' ? item.value : null));
      return temp.filter((item) => item || typeof item === 'number');
    }

    return data.value !== 'any' ? data.value : null;
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit((data) => submit.call(null, data));
      }}
      className={styles.form}
    >
      <Button buttonText="Reset column order" onClick={setDefaultColumnsOrder} />
      <SearchBar
        className={styles.searchBar}
        queryMessage={queryMessage}
        inputProps={{
          id: 'query',
          name: 'query',
          control,
          placeholder: dynamicSearchPlaceholder,
          onSearch: () => submit(getValues()),
          onChange: (v) => !v?.length && submit(getValues()),
        }}
        selectProps={{
          id: 'query_category',
          name: 'query_category',
          control,
          options: leadsSearchOptions,
          onSelectChange: () => {
            const { query } = getValues();
            if (query) submit(getValues());
          },
        }}
      />
      <div className={styles.buttons}>
        <ReloadButton isLoading={isLoading} onClick={() => submit(getValues(), true)} />
        <Button
          buttonText="Reset"
          buttonType="outline"
          isLoading={isLoading}
          type="reset"
          onClick={resetForm}
          disabled={!isFormChange(dirtyFields)}
        />
        <Button
          buttonText="Apply"
          isLoading={isLoading}
          type="submit"
          onClick={handleSubmit((data) => submit.call(null, data))}
          disabled={!isFormChange(dirtyFields)}
        />
      </div>
    </form>
  );
};

export default LeadFilter;
