// Тут необходимо зарегистрировать константу для env переменной REACT_APP_DEFAULT_ICON
export const logo_default = require('./logo_default.svg');
export const finap = require('./Finap_Limited.png');
export const nexgen = require('./nexgen.svg');
export const cyber = require('./cyber.svg');
export const corsair = require('./corsair.png');
export const terema = require('./terema.svg');
export const rise = require('./rise.svg');
export const genox = require('./genox.svg');
export const golden = require('./golden.svg');
export const ria = require('./ria.svg');
export const ufina = require('./ufina.svg');
export const jetax = require('./jetax.svg');
export const gho = require('./gho.svg');
export const wise = require('./wise.svg');
export const stargazer = require('./stargazer.svg');
export const ande = require('./andelogo.svg');
export const artis = require('./artis.svg');
export const zen = require('./zen.svg');
export const femida = require('./femidagarant.svg');
export const cybersmart = require('./cybersmart.svg');
export const vertex = require('./vertex.png');
export const bearmountain = require('./WorkTrade.png');
export const maritime = require('./maritime.svg');
export const financeadvice = require('./financeadvice.svg');
export const alaris = require('./alaris.svg');
export const serica = require('./serica.svg');
export const xmarketswolf = require('./xmarketswolf.svg');
export const tickmill = require('./tickmill.svg');
export const vega = require('./vega.svg');
export const dws = require('./dws.svg');
export const romfor = require('./romfor.svg');
export const arow = require('./arow.svg');
export const inter = require('./inter.svg');
export const axa = require('./axa.svg');
export const brsg = require('./brsg.svg');
export const hfi = require('./hfi.svg');
export const blue = require('./blue.svg');
export const kineo = require('./kineo.svg');
export const profinus = require('./profinus.svg');
export const aspect = require('./aspect.svg');
