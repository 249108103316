import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { setStyleListOldMethod } from 'api/settingsLK';
import { notify } from 'utils/notify';
import Button from 'components/ui/Button';
import ColorPicker from './ColorPicker/ColorPicker';
import styles from './styles.module.scss';

const white = '#ffffff';

type Props = {
  data: any;
  updateComponent: () => void;
};

export const ColorsForm = (props: Props) => {
  const { data, updateComponent } = props;

  const [isLoading, setIsLoading] = useState(false);

  const values = {
    body_background_color: data?.body_background_color ? data.body_background_color : white,
    border_background_color: data?.border_background_color ? data.border_background_color : white,
    header_background_color: data?.header_background_color ? data.header_background_color : white,
    sidebar_background_color: data?.sidebar_background_color
      ? data.sidebar_background_color
      : white,
    main_color: data?.main_color ? data.main_color : white,
    main_light_color: data?.main_light_color ? data.main_light_color : white,
    main_color_hover: data?.main_color_hover ? data.main_color_hover : white,
    primary_text_color: data?.primary_text_color ? data.primary_text_color : white,
    secondary_text_color: data?.secondary_text_color ? data.secondary_text_color : white,
    disabled_text_color: data?.disabled_text_color ? data.disabled_text_color : white,
    currency_color: data?.currency_color ? data.currency_color : white,
  };

  const { handleSubmit, control, formState } = useForm({
    reValidateMode: 'onChange',
    defaultValues: values,
  });

  const onSubmit = (data) => {
    setIsLoading(true);
    setStyleListOldMethod(data)
      .then((res) => {
        if (res) {
          notify({
            type: 'success',
            message: 'Style changed successfully',
            timeOut: 3000,
          });
          updateComponent();
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.formTitle}>
        <div>Colors</div>
        {formState.isDirty && (
          <Button type="submit" buttonText="Save" buttonType="outline" isLoading={isLoading} />
        )}
      </div>
      {data && (
        <div className={styles.formContent}>
          <ColorPicker
            label={'Body background color'}
            defaultValue={values.body_background_color}
            control={control}
            name="body_background_color"
          />

          <ColorPicker
            label={'Border color'}
            defaultValue={values.border_background_color}
            control={control}
            name="border_background_color"
          />

          <ColorPicker
            label={'Header background color'}
            defaultValue={values.header_background_color}
            control={control}
            name="header_background_color"
          />

          <ColorPicker
            label={'Sidebar background color'}
            defaultValue={values.sidebar_background_color}
            control={control}
            name="sidebar_background_color"
          />

          <ColorPicker
            label={'Main color'}
            defaultValue={values.main_color}
            control={control}
            name="main_color"
          />

          <ColorPicker
            label={'Main light color'}
            defaultValue={values.main_light_color}
            control={control}
            name="main_light_color"
          />

          <ColorPicker
            label={'Main color hover'}
            defaultValue={values.main_color_hover}
            control={control}
            name="main_color_hover"
          />

          <ColorPicker
            label={'Primary text color'}
            defaultValue={values.primary_text_color}
            control={control}
            name="primary_text_color"
          />

          <ColorPicker
            label={'Secondary text color'}
            defaultValue={values.secondary_text_color}
            control={control}
            name="secondary_text_color"
          />

          <ColorPicker
            label={'Disabled text color'}
            defaultValue={values.disabled_text_color}
            control={control}
            name="disabled_text_color"
          />
        </div>
      )}
    </form>
  );
};
