import Button from 'components/ui/Button';
import PopupYesOrNo from 'components/ui/PopupYesOrNo/PopupYesOrNo';
import { setStyleListOldMethod } from 'api/settingsLK';
import { notify } from 'utils/notify';
import { COLORS } from 'constants/colors';

type Props = {
  updateComponent: () => void;
};

const sendData = {
  body_background_color: COLORS.BODY_BACKGROUND_COLOR,
  border_background_color: COLORS.BORDER_BACKGROUND_COLOR,
  disabled_text_color: COLORS.DISABLED_TEXT_COLOR,
  header_background_color: COLORS.HEADER_BACKGROUND_COLOR,
  main_color: COLORS.MAIN_COLOR,
  main_color_hover: COLORS.MAIN_COLOR_HOVER,
  main_light_color: COLORS.MAIN_LIGHT_COLOR,
  primary_text_color: COLORS.PRIMARY_TEXT_COLOR,
  secondary_text_color: COLORS.SECONDARY_TEXT_COLOR,
  sidebar_background_color: COLORS.SIDEBAR_BACKGROUND_COLOR,
  currency_color: COLORS.CURRENCY_COLOR,
};

export const ResetColorsButton = ({ updateComponent }: Props) => {
  const onReset = () => {
    setStyleListOldMethod(sendData)
      .then((response) => {
        if (response) {
          notify({
            type: 'success',
            message: 'Colors changed successfully',
            timeOut: 3000,
          });
          updateComponent();
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
      });
  };

  return (
    <PopupYesOrNo
      onClick={onReset}
      headerTitle={'Reset all colors'}
      bodyText={'Are you sure you want to reset all colors?'}
      triggerBtn={<Button buttonType="outline" buttonText="Reset all colors" className="ms-auto" />}
    />
  );
};
