import classNames from 'classnames/bind';
import { LockLaminated } from 'phosphor-react';
import React, { FC } from 'react';
import ReactTooltip from 'react-tooltip';
import { makeTableData } from 'helpers/table.helper';
import Table from '../Table';
import styles from './tradingAccountsTable.module.scss';
import { usePermission } from 'utils/usePermission';
import ShowPassword from 'pages/Clients/Client/ClientTabs/TradingAccounts/TradingAccFilter/ShowPassword';
import PopupYesOrNo from '../../PopupYesOrNo/PopupYesOrNo';
import ChangeTAPassword from 'pages/Clients/Client/ClientTabs/TradingAccounts/TradingAccFilter/ChangeTAPassword.tsx';
import { Icon } from 'components/ui/Icon';
import { ITable } from 'models/components/Table/Table.ts';

const cx = classNames.bind(styles);

interface ITradingAccountsTable {
  data: any;
  perPage: number;
  showLoader: boolean;
  columns: ITable['columns'];
  saveColumnOrder: (newColumnOrder: any) => void;
  modifyDataHandler?: (data: any) => void;
  onRowSelect?: (data: any) => void;
  colSortCheck?: (colId: any) => any;
  userId: string;
  refreshApiToken?: () => void;
  actionTaMethod?: (data: any) => void;
  blockTaTradingMethod?: (id: string, value: boolean) => void;
  changeTaPasswordMethod?: (taId: string, data: any) => void;
  getBalance?: (trading_account: string, id: string) => void;
  updateComponent?: () => void;
  deleteTradingAccount?: (id: string) => void;
}

const TradingAccountsTable: FC<ITradingAccountsTable> = ({
  data,
  perPage,
  showLoader,
  columns,
  saveColumnOrder,
  modifyDataHandler,
  onRowSelect,
  colSortCheck,
  userId,
  refreshApiToken,
  actionTaMethod,
  blockTaTradingMethod,
  changeTaPasswordMethod,
  getBalance,
  updateComponent,
  deleteTradingAccount,
}) => {
  const { permissionGiven: PermissionChangePasswordTradingAccount } = usePermission(
    'admin.trading-account.change-password',
  );
  const { permissionGiven: PermissionShowBlockAccount } = usePermission(
    'admin.trading-account.block-account',
  );
  const tableData = React.useMemo(() => makeTableData(data, data.length), [data]);

  const prepearedTableData = tableData.map((item, pos) => {
    const {
      amount_type,
      balance,
      credit,
      id,
      demo,
      name,
      status,
      trading_account,
      leverage,
      type,
    } = item;

    return {
      trading_account: (
        <div className={cx('login')}>
          <span>
            {name}
            {/*<span className={cx('ta_type', type.toLowerCase())}>{type}</span>*/}
          </span>
        </div>
      ),
      login: (
        <div>
          {demo === 1 && <span style={{ color: 'var(--blue)', fontWeight: 'bold' }}>DEMO </span>}
          <span>{trading_account}</span>
        </div>
      ),
      demo: (
        <div>
          <span>{demo === 1 ? 'Yes' : 'No'}</span>
        </div>
      ),
      balance: (
        <div>
          <span className={'d-flex justify-content-between'}>
            {amount_type}&nbsp;{balance === null ? '0.00' : balance}
            <Icon
              name="arrowsClockwise"
              size={20}
              color="var(--grey-blue)"
              onClick={() => getBalance(trading_account, id)}
            />
            <ReactTooltip id="refresh" />
          </span>
        </div>
      ),
      // credit: (
      //   <div>
      //     <span>
      //       {amount_type} {credit}
      //     </span>
      //   </div>
      // ),
      leverage: (
        <div>
          <span>{leverage}</span>
        </div>
      ),
      status: (
        <div>
          <span className={cx('status', status)}>{status}</span>
        </div>
      ),
      action: (
        <div className={cx('action')}>
          <ShowPassword tradingAccount={item} triggerBtn={<Icon name="eye" size={20} />} />
          <ReactTooltip id="showPass" />

          {PermissionShowBlockAccount ? (
            <LockLaminated
              size={20}
              onClick={() =>
                actionTaMethod({
                  taId: id,
                  blocked: status === 'active' || status === 'blocked-trading',
                })
              }
              data-tip={
                status === 'active' || status === 'blocked-trading'
                  ? 'Block account'
                  : 'Activate account'
              }
              data-for="blocked"
            />
          ) : null}

          {PermissionShowBlockAccount ? (
            <LockLaminated
              size={20}
              onClick={() => blockTaTradingMethod(id, status === 'active' || status === 'blocked')}
              data-tip={
                status === 'active' || status === 'blocked' ? 'Block trading' : 'Allow trading'
              }
              data-for="blocked-trading"
              color="blue"
            />
          ) : null}
          <ReactTooltip id="blocked" />
          <ReactTooltip id="blocked-trading" />

          <PopupYesOrNo
            onClick={() => deleteTradingAccount(id)}
            headerTitle={'Delete trading account'}
            bodyText={'Are you sure you want to delete trading account?'}
            triggerBtn={<Icon name="trash" size={20} color="#ff0000" />}
          />
          <ReactTooltip id="delete" type={'error'} />

          {PermissionChangePasswordTradingAccount ? (
            <ChangeTAPassword
              updateComponent={() => updateComponent()}
              tradingAccount={item}
              triggerBtn={<Icon name="resetPassword" size={20} />}
            />
          ) : null}
          <ReactTooltip id="editPass" />
        </div>
      ),
    };
  });

  const checkSortEnable = (colId) => {
    // switch (colId) {
    //   case 'trading_account':
    //   case 'login':
    //   case 'balance':
    //   case 'credit':
    //   // case 'leverage':
    //   case 'status':
    //     // case 'server':
    //     return true;
    //   default:
    //     return false;
    // }
  };

  const tableDataMeMo = React.useMemo(() => prepearedTableData, [tableData]);

  return (
    <Table
      saveColumnOrder={saveColumnOrder}
      columns={columns}
      data={tableDataMeMo}
      perPage={perPage}
      showLoader={showLoader}
      onRowSelect={onRowSelect}
      colSortCheck={colSortCheck || checkSortEnable}
      className={cx('clients-table')}
      isSelected={false}
      getCellProps={(cellInfo, cx) => {
        const { index } = cellInfo.row;
        const odd = index % 2 !== 0;

        return {
          style: {
            backgroundColor: `${odd ? 'var(--grey-600)' : 'var(--white)'}`,
          },
        };
      }}
    />
  );
};

export default TradingAccountsTable;
