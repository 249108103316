import useDynamicSearchPlaceholder from 'utils/useDynamicSearchPlaceholder';
import { useSearchCategoriesOptions } from 'utils/useGetSearchCategoriesOptions';
import { useFormContext } from 'react-hook-form';
import Button from 'components/ui/Button/Button';
import { isFormChange } from 'utils/isFormChange';
import { CryptoTransactionFilter } from 'models/CustomerService/CryptoTransactionFilter';
import { ReloadButton } from 'components/ui/ReloadButton';
import { SearchBar } from 'components/ui/SearchBar';
import styles from './transactions-filter.module.scss';

type Props = {
  onSubmit: (data: CryptoTransactionFilter) => void;
  isLoading: boolean;
  queryMessage: string;
  setDefaultColumnsOrder: () => void;
};

const CryptoTransactionsFilters = (props: Props) => {
  const { onSubmit, isLoading, queryMessage, setDefaultColumnsOrder } = props;

  const cryptoTransactionsSearchOptions = useSearchCategoriesOptions('cryptoTransaction');

  const {
    handleSubmit,
    reset,
    watch,
    getValues,
    control,
    formState: { dirtyFields },
  } = useFormContext<CryptoTransactionFilter>();

  const dynamicSearchPlaceholder = useDynamicSearchPlaceholder(
    watch,
    getValues,
    cryptoTransactionsSearchOptions,
  );

  const submit = (data) => {
    const filterData = prepareFiltersData(data);

    onSubmit(filterData);
  };

  const resetForm = () => {
    onSubmit(null);
    reset();
  };

  const prepareFiltersData = (data: any) => {
    const filterdata = {
      query: data['query'],
      status: data['status']?.value,
    };

    if (data['query_category']) {
      filterdata['searchCategory'] = data['query_category']?.value;
    }

    return filterdata;
  };

  return (
    <form onSubmit={handleSubmit(submit)} className={styles.form}>
      <Button buttonText="Reset column order" onClick={setDefaultColumnsOrder} />
      <SearchBar
        className={styles.searchBar}
        queryMessage={queryMessage}
        inputProps={{
          id: 'query',
          name: 'query',
          control,
          placeholder: dynamicSearchPlaceholder,
          onSearch: () => submit(getValues()),
          onChange: (v) => v?.length === 0 && submit(getValues()),
        }}
        selectProps={{
          id: 'query_category',
          name: 'query_category',
          control,
          options: cryptoTransactionsSearchOptions,
          onSelectChange: () => getValues()?.query && submit(getValues()),
        }}
      />
      <div className={styles.buttons}>
        <ReloadButton isLoading={isLoading} onClick={() => submit(getValues())} />
        <Button
          buttonType="outline"
          buttonText="Reset"
          isLoading={isLoading}
          type="reset"
          onClick={resetForm}
          disabled={!isFormChange(dirtyFields)}
        />
        <Button
          buttonText="Apply"
          isLoading={isLoading}
          type="submit"
          onClick={handleSubmit(submit)}
          disabled={!isFormChange(dirtyFields)}
        />
      </div>
    </form>
  );
};

export default CryptoTransactionsFilters;
