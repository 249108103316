export const BROWN = '#462c1c';
export const PURPLE = '#722ed1';
export const GREEN = '#795c11';
export const ORANGE = '#8b471a';
export const BLUE = '#04145c';
export const LIGHT_BLUE = '#8bcbf5';

export const DEFAULT_COLORS = [BROWN, PURPLE, GREEN, ORANGE, BLUE, LIGHT_BLUE];
export const DEFAULT_COLORS_FOR_DYNAMIC_STATUSES = [
  PURPLE,
  '#A18594',
  BROWN,
  GREEN,
  '#BEBD7F',
  '#E4717A',
  '#FFBCAD',
  '#FFD1DC',
  '#3EB489',
  '#7FB5B5',
  '#A8E4A0',
  '#FF8C69',
  '#EFA94A',
  '#FFDB8B',
  '#52b0ff',
  LIGHT_BLUE,
];
export const COLORS = {
  MAIN_COLOR: process.env.REACT_APP_MAIN_COLOR ||'#722ed1',
  DISABLED_TEXT_COLOR: process.env.REACT_APP_DISABLED_TEXT_COLOR ||'#8c8c8c',
  HEADER_BACKGROUND_COLOR: process.env.REACT_APP_HEADER_BACKGROUND_COLOR ||'#ffffff',
  MAIN_COLOR_HOVER: process.env.REACT_APP_MAIN_COLOR_HOVER ||'#540cb9',
  MAIN_LIGHT_COLOR: process.env.REACT_APP_MAIN_LIGHT_COLOR ||'#f9f1ff',
  PRIMARY_TEXT_COLOR: process.env.REACT_APP_PRIMARY_TEXT_COLOR ||'#262626',
  SECONDARY_TEXT_COLOR: process.env.REACT_APP_SECONDARY_TEXT_COLOR ||'#595959',
  SIDEBAR_BACKGROUND_COLOR: process.env.REACT_APP_SIDEBAR_BACKGROUND_COLOR ||'#fff',
  DEFAULT_COLOR: PURPLE,
  BODY_BACKGROUND_COLOR: process.env.REACT_APP_BODY_BACKGROUND_COLOR ||'#fff',
  BORDER_BACKGROUND_COLOR: process.env.REACT_APP_BORDER_BACKGROUND_COLOR ||'#722ed1',
  CURRENCY_COLOR: process.env.REACT_APP_CURRENCY_COLOR ||'#fff',
};


export const DEFAULT_COLORS_LK = {
  DEFAULT: {
    disabled_text_color: '#fff',
    header_background_color: '#fff',
    main_color: '#fff',
    main_color_hover: '#fff',
    main_light_color: '#fff',
    primary_text_color: '#fff',
    secondary_text_color: '#fff',
    sidebar_background_color: '#fff',
    body_background_color: '#fff',
    border_background_color: '#fff',
    currency_color: '#fff',
  },
  BROWN: {
    disabled_text_color: '#8c8c8c',
    header_background_color: '#c69c84',
    main_color: BROWN,
    main_color_hover: '#9f7963',
    main_light_color: '#cfb4a2',
    primary_text_color: '#262626',
    secondary_text_color: '#595959',
    sidebar_background_color: '#c69c84',
    body_background_color: '#c69c84',
    border_background_color: BROWN,
    currency_color: BROWN,
  },
  PURPLE: {
    disabled_text_color: COLORS.DISABLED_TEXT_COLOR,
    header_background_color: COLORS.HEADER_BACKGROUND_COLOR,
    main_color: COLORS.MAIN_COLOR,
    main_color_hover: COLORS.MAIN_COLOR_HOVER,
    main_light_color: COLORS.MAIN_LIGHT_COLOR,
    primary_text_color: COLORS.PRIMARY_TEXT_COLOR,
    secondary_text_color: COLORS.SECONDARY_TEXT_COLOR,
    sidebar_background_color: COLORS.SIDEBAR_BACKGROUND_COLOR,
    body_background_color: COLORS.MAIN_LIGHT_COLOR,
    border_background_color: COLORS.MAIN_COLOR,
    currency_color: COLORS.DEFAULT_COLOR,
  },
  GREEN: {
    disabled_text_color: '#8c8c8c',
    header_background_color: '#beb775',
    main_color: GREEN,
    main_color_hover: '#4a662f',
    main_light_color: '#beb775',
    primary_text_color: '#262626',
    secondary_text_color: '#595959',
    sidebar_background_color: '#beb775',
    body_background_color: '#beb775',
    border_background_color: GREEN,
    currency_color: GREEN,
  },
  ORANGE: {
    disabled_text_color: '#8c8c8c',
    header_background_color: '#e49445',
    main_color: ORANGE,
    main_color_hover: '#543431',
    main_light_color: '#c67a30',
    primary_text_color: '#262626',
    secondary_text_color: '#595959',
    sidebar_background_color: '#e49445',
    body_background_color: '#e49445',
    border_background_color: ORANGE,
    currency_color: ORANGE,
  },
  BLUE: {
    disabled_text_color: '#8c8c8c',
    header_background_color: '#b5cef5',
    main_color: BLUE,
    main_color_hover: '#53699e',
    main_light_color: '#acadd2',
    primary_text_color: '#262626',
    secondary_text_color: '#595959',
    sidebar_background_color: '#b5cef5',
    body_background_color: '#b5cef5',
    border_background_color: BLUE,
    currency_color: BLUE,
  },
  LIGHT_BLUE: {
    disabled_text_color: '#8c8c8c',
    header_background_color: '#1286df',
    main_color: LIGHT_BLUE,
    main_color_hover: '#04457d',
    main_light_color: '#acadd2',
    primary_text_color: '#262626',
    secondary_text_color: '#595959',
    sidebar_background_color: '#1286df',
    body_background_color: '#1286df',
    border_background_color: LIGHT_BLUE,
    currency_color: LIGHT_BLUE,
  },
};
