import React, { useState } from 'react';
import { Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { DEFAULT_COLORS_LK } from 'constants/colors';
import { notify } from 'utils/notify';
import { setStyleListOldMethod } from 'api/settingsLK/index.ts';
import { setColorsLK } from './utils';
import { ColorPickerCircle } from './ColorPickerCircle/ColorPickerCircle';
import Button from 'components/ui/Button';
import styles from './styles.module.scss';

type Props = {
  data: any;
  updateComponent: () => void;
};

export const SetColorsForm = (props: Props) => {
  const { data, updateComponent } = props;

  const [isLoading, setIsLoading] = useState(false);

  const { handleSubmit, control, formState } = useForm({
    reValidateMode: 'onChange',
    defaultValues: DEFAULT_COLORS_LK.DEFAULT,
  });

  const onSubmit = (data) => {
    setIsLoading(true);

    const sendData = setColorsLK(data?.currency_color);

    setStyleListOldMethod(sendData)
      .then((res) => {
        if (res) {
          notify({
            type: 'success',
            message: 'Style changed successfully',
            timeOut: 3000,
          });
          updateComponent();
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
      })
      .finally(() => setIsLoading(false));
  };
  return (
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.formTitle}>
        <div>Set default colors</div>
        {formState.isDirty && (
          <Button
            type="submit"
            buttonText="Save changes"
            buttonType="outline"
            isLoading={isLoading}
          />
        )}
      </div>
      {data && (
        <div className={styles.formContent}>
          <ColorPickerCircle
            control={control}
            name="currency_color"
            selected_color={data?.currency_color || 'var(--white)'}
          />
        </div>
      )}
    </form>
  );
};
