import classNames from 'classnames/bind';
import Button from 'components/ui/Button';
import FormInput from 'components/ui/FormInput';

import React, { useEffect, useState } from 'react';

import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { updatePartnerProfileMethod } from 'api/partners/index';
import FormInputAutoExternalId from 'components/ui/FormInputAutoExternalId';
import JokerMultiInput from 'components/ui/JokerMultiInput/JokerMultiInput';
import { EXTERNAL_ID_VALIDATION, VALID_EMAIL } from 'constants/validators.js';
import { notify } from 'utils/notify.tsx';
import { usePermission } from 'utils/usePermission.tsx';
import styles from '../partner-page.module.scss';
import { CURRENT_DOMAIN_LK } from 'config';
import { useSelector } from 'react-redux';
import { getEnableMT } from 'constants/selectors';
import { ManagementPartner } from 'models/Management/Partners/Partner.js';
import { CountryListValue } from 'models/Countries.js';
import CheckBox from 'components/ui/CheckBox';
import JokerSelect from 'components/ui/JokerSelect/JokerSelect';
import { MultiSelect } from 'components/ui/MultiSelect';

const cx = classNames.bind(styles);

const checkIPV4 =
  /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
const checkIPV6 =
  /^(([0-9a-fA-F]{1,4}:){7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|(::(ffff(:0{1,4}){0,1}:){0,1}){0,1}([0-9a-fA-F]{1,4}:){1,4}:(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.([0-9a-fA-F]{1,4}:){1,2}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.([0-9a-fA-F]{1,4}:){1,2}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))$/;

type Props = {
  formData: ManagementPartner;
  updateComponent: () => void;
  countryList: CountryListValue[];
};

const PartnerPersonalInfoForm = (props: Props) => {
  const { formData, updateComponent, countryList } = props;

  const { permissionGiven: PermissionUpdate } = usePermission('admin.partner.update');

  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [err, setErr] = useState([]);
  const [linkLk, setLinkLK] = useState('');

  const ENABLE_MT = useSelector(getEnableMT);

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors, isDirty },
  } = useForm({
    reValidateMode: 'onChange',
  });

  const getValue = (data) => {
    if (!data || (Array.isArray(data) && !data.length)) {
      return;
    }

    if (Array.isArray(data) && data.length) {
      const temp = data.map((item) => (item.value !== 'any' ? item.value : null));
      return temp.filter((item) => item);
    }

    return data.value !== 'any' ? data.value : null;
  };

  const onSubmit = (data) => {
    setIsLoading(true);
    const sendData = {
      ...data,
      country_id: getValue(data['country_id']),
    };

    updatePartnerProfileMethod(formData.id, sendData)
      .then((res) => {
        if (res) {
          notify({
            type: 'success',
            message: 'Personal info updated successfully',
            timeOut: 3000,
          });
          updateComponent();
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
      })
      .finally(() => setIsLoading(false));
  };

  const checkValidMultiInput = (arr) => {
    let invalid = [];
    arr.forEach((item) => {
      if (!checkIPV4.test(item) && !checkIPV6.test(item)) {
        invalid.push(item);
      }
    });
    setErr(invalid);
  };

  const generatePassword = (pass) => {
    setValue('password', pass);
  };

  const generateExternalId = (pass) => {
    setValue('external_id', pass);
  };

  useEffect(() => {
    if (Object.keys(formData).length !== 0) {
      setIsLoading(false);
      setOpen(formData?.api_allow_client_add === 1);
      reset({
        first_name: formData.first_name,
        email: formData.email,
        external_id: formData.external_id,
        password: formData.password,
        country_id: formData?.country
          ? { value: formData?.country?.id, label: formData?.country?.name }
          : '',
        restricted_countries: formData?.restricted_countries,
        phone: formData.phone,
        ips: formData.ips,
        public_api_status: formData.public_api_status,
        show_notes: formData?.show_notes,
        show_sales_status: formData?.show_sales_status,
        show_ftd_amount: formData?.show_ftd_amount,
        // show_kyc_status: formData?.show_kyc_status,
        mark_as_source: formData?.mark_as_source,
        api_allow_client_add: formData?.api_allow_client_add,
        client_autologin: formData?.client_autologin,
      });
    }
    createApiLink();
  }, [countryList, formData]);

  const createApiLink = () => {
    const link = `${CURRENT_DOMAIN_LK}/partner?key=${formData.external_id}`;
    setLinkLK(link);
  };

  return (
    <form className={cx('form')} onSubmit={handleSubmit(onSubmit)}>
      <div className={isLoading ? cx('p-0', 'blur') : cx('p-0')}>
        <Row>
          <Col md={12}>
            <div className={styles.tabTitle}>
              Personal information
              {PermissionUpdate ? (
                <>{isDirty && <Button buttonText="Save changes" buttonType="outline" />}</>
              ) : null}
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={4} lg={3} xs={12} className="mb-4">
            <FormInput
              rules={{
                required: 'First name field is required',
                validate: {
                  correctFirstName: (value) => value.length < 50 || 'First name too long',
                },
              }}
              name="first_name"
              type="text"
              label="Name"
              control={control}
              errors={errors.first_name}
            />
          </Col>
          <Col md={4} lg={3} xs={12} className="mb-4">
            <FormInput
              rules={{
                required: 'Email field is required',
                validate: VALID_EMAIL,
              }}
              disabled={true}
              name="email"
              type="email"
              label="Email"
              control={control}
              errors={errors.email}
            />
          </Col>
          {ENABLE_MT ? (
            <Col md={4} lg={3} xs={12} className="mb-4">
              <FormInputAutoExternalId
                generatePassword={generateExternalId}
                label={'External ID'}
                id="external_id"
                control={control}
                name="external_id"
                placeholder={'External ID'}
                errors={errors.external_id}
                rules={{
                  required: 'External ID field is required',
                  validate: EXTERNAL_ID_VALIDATION,
                }}
              />
            </Col>
          ) : null}
          <Col md={4} lg={3} xs={12} className="mb-4">
            <FormInput
              rules={{
                required: 'Password field is required',
              }}
              disabled={true}
              name="password"
              type="text"
              label="Password"
              control={control}
              errors={errors.password}
            />
            {/*<FormInputAutoPassword*/}
            {/*  generatePassword={generatePassword}*/}
            {/*  label={'Password'}*/}
            {/*  id='password'*/}
            {/*  disabled*/}
            {/*  control={control}*/}
            {/*  name='password'*/}
            {/*  placeholder={'Password'}*/}
            {/*  errors={errors.password}*/}
            {/*  rules={{*/}
            {/*    required: 'Password field is required',*/}
            {/*    validate: PASSWORD_INPUT_VALIDATION,*/}
            {/*  }}*/}
            {/*/>*/}
            {/*<FormInput*/}
            {/*  rules={{*/}
            {/*    required: 'External ID field is required',*/}
            {/*  }}*/}
            {/*  disabled={false}*/}
            {/*  name="external_id"*/}
            {/*  type="text"*/}
            {/*  label="External ID"*/}
            {/*  control={control}*/}
            {/*  errors={errors.external_id}*/}
            {/*/>*/}
          </Col>
        </Row>
        <Row>
          <Col md={12} className="mb-3">
            <CheckBox
              type="checkbox"
              id="public_api_status"
              name="public_api_status"
              control={control}
              label="Allow API access"
            />
          </Col>
        </Row>
        <Row>
          <Col md={12} className="mb-3">
            <CheckBox
              type="checkbox"
              id="api_allow_client_add"
              name="api_allow_client_add"
              control={control}
              label="Allow downloading clients via API"
              setOpen={setOpen}
            />
            {linkLk && open && ENABLE_MT ? (
              <a className={styles.linkLk} target={'_blank'} href={linkLk}>
                {linkLk}
              </a>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className={styles.tabTitle}>Contacts</div>
          </Col>
        </Row>
        <Row>
          <Col md={4} className="mb-4">
            <FormInput name="phone" type="text" label="Phone" control={control} />
            {/* <Button buttonText="Verify" /> */}
          </Col>
          <Col md={4} className="mb-4">
            <JokerSelect
              label="Country"
              control={control}
              id="country_id"
              name="country_id"
              placeholder={'Search'}
              rightaligned={true}
              options={countryList}
            />
          </Col>
          <Col md={4} className="mb-4">
            <MultiSelect
              placeholder="--Select--"
              label="Restricted countries"
              control={control}
              id="restricted_countries"
              name="restricted_countries"
              options={countryList}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={8} md={12} className="mb-4">
            <JokerMultiInput
              checkValidMultiInput={(arr) => checkValidMultiInput(arr)}
              placeholder={'White listed IPs'}
              list={formData.ips}
              label={'White listed IPs'}
              control={control}
              id="ips"
              name="ips"
              errors={errors.ips}
              err={err}
              errorText={'The IP address is invalid: '}
              required
            />
          </Col>
        </Row>
        <Row>
          <Col md={4} className="d-flex flex-column row-gap-3">
            <CheckBox
              type="checkbox"
              id={'show_notes'}
              name="show_notes"
              control={control}
              label={'Show notes'}
            />
            <CheckBox
              type="checkbox"
              id={'show_sales_status'}
              name="show_sales_status"
              control={control}
              label={'Show Sales Status'}
            />
            <CheckBox
              type="checkbox"
              id={'show_ftd_amount'}
              name="show_ftd_amount"
              control={control}
              label={'Show FTD Amount'}
            />
            {ENABLE_MT ? (
              <CheckBox
                type="checkbox"
                id="client_autologin"
                name="client_autologin"
                control={control}
                label="Client autologin"
              />
            ) : null}
            {/*<CheckBox*/}
            {/*  type='checkbox'*/}
            {/*  id={'show_kyc_status'}*/}
            {/*  name='show_kyc_status'*/}
            {/*  control={control}*/}
            {/*  label={'Show KYC Status'}*/}
            {/*/>*/}
          </Col>
        </Row>
      </div>
    </form>
  );
};

export default PartnerPersonalInfoForm;
