import React, { useEffect, useRef } from 'react';
import useDynamicSearchPlaceholder from 'utils/useDynamicSearchPlaceholder';
import { useSearchCategoriesOptions } from 'utils/useGetSearchCategoriesOptions';
import { useFormContext } from 'react-hook-form';
import Button from 'components/ui/Button';
import getUTCtime from 'utils/getUTCTime';
import { isFormChange } from 'utils/isFormChange';
import { CryptoWalletsFilter } from 'models/CustomerService/CryptoWalletsFilter';
import { ReloadButton } from 'components/ui/ReloadButton';
import { SearchBar } from 'components/ui/SearchBar';
import styles from './wallet-filter.module.scss';

type Props = {
  onSubmit: (data: CryptoWalletsFilter) => void;
  reloadFilters: boolean;
  queryMessage: string;
  setDefaultColumnsOrder: () => void;
};

const CryptoPaymentsFilters = (props: Props) => {
  const { onSubmit, reloadFilters, queryMessage, setDefaultColumnsOrder } = props;

  const componentMounted = useRef(true);
  const cryptoWalletsSearchOptions = useSearchCategoriesOptions('cryptoWallet');

  const {
    handleSubmit,
    reset,
    watch,
    getValues,
    control,
    formState: { dirtyFields },
  } = useFormContext<CryptoWalletsFilter>();

  const dynamicSearchPlaceholder = useDynamicSearchPlaceholder(
    watch,
    getValues,
    cryptoWalletsSearchOptions,
  );

  const submit = (data) => {
    const type =
      data['type'] && data['type'].value !== 'any' ? data['type'].value.toUpperCase() : null;
    const available =
      data['available'] && data['available'].value !== 'any' ? data['available'].value : null;

    if (available !== null) {
      const from = getUTCtime(null, data.time_from);
      const to = getUTCtime(null, data.time_to);

      onSubmit({
        query: data['query'],
        searchCategory: data['query_category']?.value,
        type,
        available,
        time_from: Math.round(from / 1000),
        time_to: Math.round(to / 1000),
      });
    } else {
      onSubmit({
        query: data['query'],
        searchCategory: data['query_category']?.value,
        type,
        available: null,
      });
    }
  };

  const resetForm = () => {
    onSubmit(null);
    reset();
  };

  useEffect(() => {
    return () => {
      componentMounted.current = false;
    };
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit(submit)} className={styles.form}>
        <Button buttonText="Reset column order" onClick={setDefaultColumnsOrder} />
        <SearchBar
          className={styles.searchBar}
          queryMessage={queryMessage}
          inputProps={{
            id: 'query',
            name: 'query',
            control,
            placeholder: dynamicSearchPlaceholder,
            onSearch: () => submit(getValues()),
            onChange: (v) => v?.length === 0 && submit(getValues()),
          }}
          selectProps={{
            id: 'query_category',
            name: 'query_category',
            control,
            options: cryptoWalletsSearchOptions,
            onSelectChange: () => {
              const { query } = getValues();
              if (query) submit(getValues());
            },
          }}
        />
        <div className={styles.buttons}>
          <ReloadButton isLoading={reloadFilters} onClick={() => submit(getValues())} />
          <Button
            buttonText="Reset"
            buttonType="outline"
            isLoading={reloadFilters}
            type="reset"
            onClick={() => resetForm()}
            disabled={!isFormChange(dirtyFields)}
          />
          <Button
            buttonText="Apply"
            isLoading={reloadFilters}
            type="submit"
            onClick={handleSubmit(submit)}
            disabled={!isFormChange(dirtyFields)}
          />
        </div>
      </form>
    </>
  );
};

export default CryptoPaymentsFilters;
