import { SearchBarSelect, SearchBarSelectProps } from './SearchBarSelect/SearchBarSelect';
import { SearchBarInput, SearchBarInputProps } from './SearchBarInput/SearchBarInput';
import cn from 'classnames';
import styles from './search-bar.module.scss';

type Props = {
  inputProps: SearchBarInputProps;
  selectProps?: SearchBarSelectProps;
  className?: string;
  queryMessage?: string;
};

export const SearchBar = ({ inputProps, selectProps, className, queryMessage }: Props) => {
  const hasSelect = !!selectProps;

  return (
    <div className={cn(styles.wrapper, className)}>
      <div className={cn(styles.field, !hasSelect && styles.field__onlySearch)}>
        {hasSelect && (
          <>
            <SearchBarSelect {...selectProps} />
            <div className={styles.divider} />
          </>
        )}
        <SearchBarInput {...inputProps} />
      </div>
      <span className={styles.queryMessage}>{queryMessage}</span>
    </div>
  );
};
