import React, { useEffect, useRef, useState } from 'react';
import { findTeamsMethod } from 'api/managements/teams/index';
import TeamsTable from 'components/ui/Table/TeamsTable';
import TablePagination from 'components/ui/TablePagination';
import { usePermission } from 'utils/usePermission.tsx';
import Page from '../../Page';
import { DashboardLayout } from '../../UserView/DashboardLayout';
import TeamsFilter from './Filter';

import styles from './teams-page.module.scss';
import { getTablePageParams } from 'utils/getTablePageParams.js';
import { TableLinks, TableMeta } from 'models/Table.js';
import { HierarchyTeam } from 'models/Hierarchy/HierarchyData.js';
import { ManagementTeamFilter } from 'models/Management/Teams/Filter.js';
import { useAppDispatch } from 'store';
import { toggleScrollToTop } from 'store/slice/tableSlice';
import { usePageTitle } from 'utils/usePageTitle';
import { TeamsPageTitle } from './TeamsPageTitle';
import { useTeamsPage } from './useTeamsPage';
import { FormProvider } from 'react-hook-form';
import { TeamsFields } from './Fields/TeamsFields';
import { TableLayout } from 'components/ui/Table/TableLayout';

const title = 'Teams';

const TeamsPage = () => {
  const { permissionGiven: PermissionList } = usePermission('admin.team.index');

  const [teams, setTeams] = useState<HierarchyTeam[]>([]);
  const dispatch = useAppDispatch();

  const [isListLoading, setIsListLoading] = useState(true);
  const [filters, setFilters] = useState<ManagementTeamFilter>(null);
  const [tableMeta, setTableMeta] = useState<TableMeta>();
  const [tableLinks, setTableLinks] = useState<TableLinks>();
  const [perPageCount, setPerPageCount] = useState(10);
  const [queryMessage, setQueryMessage] = useState('');

  const componentMounted = useRef(false);

  usePageTitle(title);

  useEffect(() => {
    if (PermissionList) {
      componentMounted.current = true;
      getTeams();
    }
    return () => {
      componentMounted.current = false;
    };
  }, [PermissionList]);

  const getTeams = (options?, data?) => {
    setIsListLoading(true);

    findTeamsMethod(getTablePageParams(options, perPageCount, tableLinks), data || filters)
      .then((res) => {
        if (componentMounted.current) {
          setTeams(res.data.data);
          setTableMeta(res.data.meta);
          setTableLinks(res.data.links);
          setPerPageCount(res.data.meta.per_page);
          setQueryMessage(res.data?.message);
        }
      })
      .catch(console.log)
      .finally(() => setIsListLoading(false));
  };

  const { form, table } = useTeamsPage();

  return (
    <DashboardLayout>
      <Page
        isStickyTitle
        title={<TeamsPageTitle total={tableMeta?.total} updateComponentCallback={getTeams} />}
      >
        <>
          <FormProvider {...form}>
            <TeamsFields />

            <TableLayout
              header={
                <TeamsFilter
                  onSubmit={(data) => {
                    setFilters(data);
                    getTeams({ navigate: 'first', perPage: data?.filter?.limit }, data);
                  }}
                  isLoading={isListLoading}
                  queryMessage={queryMessage}
                  setDefaultColumnsOrder={table.setDefaultColumnsOrder}
                />
              }
            >
              {PermissionList && (
                <TeamsTable
                  data={teams}
                  perPage={teams?.length}
                  showLoader={isListLoading}
                  modifyDataHandler={() => getTeams()}
                  columns={table.columns}
                  saveColumnOrder={table.saveColumnOrder}
                />
              )}
            </TableLayout>
          </FormProvider>
          {teams?.length ? (
            <TablePagination
              currentPage={tableMeta?.current_page}
              pagesLength={tableMeta?.last_page}
              perPageChange={(value) => {
                setPerPageCount(value);
                getTeams({ perPage: value });
                dispatch(toggleScrollToTop());
              }}
              goToSelectedPage={(page) => {
                getTeams({ page });
                dispatch(toggleScrollToTop());
              }}
            />
          ) : null}
        </>
      </Page>
    </DashboardLayout>
  );
};

export default TeamsPage;
