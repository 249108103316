import classNames from 'classnames/bind';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { getClientInfo } from 'api/clients';
import timezone from 'constants/timezone.json';
import { usePermission } from 'utils/usePermission.tsx';
import Page from '../../Page/Page';
import { DashboardLayout } from '../../UserView/DashboardLayout';
import styles from './client-page.module.scss';
import AdressForm from './ClientForms/AdressForm';
import ContactForm from './ClientForms/ContactForm';
import KycForm from './ClientForms/KycForm';
import PersonalInfoForm from './ClientForms/PersonalInfoForm';
import Callbacks from './ClientTabs/Callbacks';
import Feed from './ClientTabs/Feed';
import Files from './ClientTabs/Files';
import NotesTabs from './ClientTabs/NotesTabs';
import Payments from './ClientTabs/Payments';
import TradingActivity from './ClientTabs/TradingActivity';
import OpenedPositions from './ClientTabs/OpenedPositions';
import Deals from './ClientTabs/Deals';
import { useSelector } from 'react-redux';
import { getEnableMT } from 'constants/selectors';
import TradingAccounts from './ClientTabs/TradingAccounts/TradingAccounts';
import { getCountryList } from 'api/country';
import { getLangList } from 'api/lang';
import { getBalanceInfo, updateProfitInfo } from 'api/deposit';
import { notify } from 'utils/notify';
import { BalancesData } from 'models/Balances';
import { ICountry } from 'models/Countries';
import { Client as ClientType } from 'models/Clients/Clients';
import { Language } from 'models/Languages';
import CryptoWalletsTab from './ClientTabs/CryptoWalletsTab/CryptoWalletsTab.tsx';
import ErrorPage from '../../ErrorPage';
import { usePageTitle } from 'utils/usePageTitle.ts';
import { ClientPageHeader } from './ClientPageHeader.tsx';
import { ClientInfo } from './ClientInfo/ClientInfo.tsx';
import { Spinner } from 'components/ui/Spinner/Spinner.tsx';

const cx = classNames.bind(styles);

const Client = () => {
  const { permissionGiven: PermissionAddressInfo } = usePermission(
    'admin.client.view-address-info',
  );
  const { permissionGiven: PermissionViewUserLogs } = usePermission('admin.logs.user');
  const { permissionGiven: PermissionListCallback } = usePermission('admin.callback.index');
  const { permissionGiven: PermissionNotesCreate } = usePermission('admin.notes.create');
  const { permissionGiven: PermissionPersonalInfo } = usePermission(
    'admin.client.view-personal-info',
  );
  const { permissionGiven: PermissionShowTableCryptoWalletsUserById } = usePermission(
    'admin.client.crypto_wallets.index',
  );

  const params = useParams();
  const [clientName, setClientName] = useState('');
  const [clientData, setClientData] = useState<ClientType | undefined>();
  const [isListLoading, setIsListLoading] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [countryListArr, setCountryListArr] = useState<ICountry[]>([]);
  const [langListArr, setlangListArr] = useState<Language[]>([]);
  const [hasError, setError] = useState(false);
  const [balanceInfo, setBalanceInfo] = useState<BalancesData | undefined>();
  const ENABLE_MT = useSelector(getEnableMT);

  const title = 'Client - ' + clientName;

  const componentMounted = useRef(false);

  useEffect(() => {
    componentMounted.current = true;
    return () => {
      componentMounted.current = false;
    };
  }, []);

  useEffect(() => {
    getCountries();
    getLanguages();
  }, []);

  const getCountries = () => {
    getCountryList()
      .then((res) => {
        if (res.status === 200) {
          setCountryListArr(res.data.data);
        }
      })
      .catch(console.log);
  };

  const getLanguages = () => {
    getLangList()
      .then((res) => {
        if (res.status === 200) {
          setlangListArr(res.data.data);
        }
      })
      .catch(console.log);
  };

  const getClient = (params?: any) => {
    setIsListLoading(true);
    fetchBalanceInfo(params);

    getClientInfo(params.id)
      .then((res) => {
        if (res.status === 200) {
          setClientData(res.data.data);
          setClientName(
            res.data.data.user_profile.first_name + ' ' + res.data.data.user_profile.last_name,
          );
          setIsLoading(false);
          setError(false);
        }
      })
      .catch((err) => {
        if (err.response.status === 403) {
          setError(true);
        }
      })
      .finally(() => {
        setIsListLoading(false);
      });
  };

  const fetchBalanceInfo = async (params, period?: string, refreshProfit?: boolean) => {
    let func: () => Promise<any> = () => Promise.resolve();

    if (refreshProfit) {
      func = () => updateProfitInfo(params.id);
    }

    return func()
      .then(() => getBalanceInfo(params.id, period))
      .then((res) => {
        if (res.status === 200) {
          setBalanceInfo(res.data.data);
        }
      })
      .catch((err) => {
        notify({
          type: 'error',
          message: err.response,
          timeOut: 3000,
        });
      });
  };

  usePageTitle(title);

  useEffect(() => {
    getClient(params);
  }, [params]);

  if (hasError) {
    return <ErrorPage />;
  }

  return (
    <DashboardLayout>
      {!isLoading ? (
        <Page
          isStickyTitle
          title={
            <ClientPageHeader
              client={clientData}
              isListLoading={isListLoading}
              isShowNotes={showNotes}
              notesCreatePermission={PermissionNotesCreate}
              onToggleNotes={(v) => setShowNotes((p) => !p)}
              updateCallback={() => getClient(params)}
            />
          }
        >
          <>
            <ClientInfo
              client={clientData}
              balance={balanceInfo}
              fetchBalance={fetchBalanceInfo}
              updateCallback={() => getClient(params)}
            />
            <div className={styles.tabs}>
              <Tabs>
                <div className={cx('tabs__list-wrap')}>
                  <TabList>
                    <Tab>Profile</Tab>
                    <Tab>Payments</Tab>
                    {ENABLE_MT ? (
                      <>
                        <Tab>Trading Activity</Tab>
                        <Tab>Opened positions</Tab>
                        <Tab>Deals</Tab>
                        <Tab>Accounts</Tab>
                      </>
                    ) : null}
                    {PermissionListCallback ? <Tab>Callbacks</Tab> : null}
                    <Tab>Files</Tab>
                    <Tab>Notes</Tab>
                    {PermissionViewUserLogs ? <Tab>Feed</Tab> : null}
                    {PermissionShowTableCryptoWalletsUserById ? <Tab>Crypto Wallets</Tab> : null}
                  </TabList>
                </div>

                <TabPanel>
                  <div className={styles.tabContainer}>
                    {PermissionPersonalInfo ? (
                      <PersonalInfoForm
                        formData={clientData}
                        countryList={countryListArr}
                        timeZone={timezone}
                        langList={langListArr}
                        updateComponent={() => getClient(params)}
                      />
                    ) : null}
                    {PermissionAddressInfo ? (
                      <AdressForm
                        formData={clientData}
                        countryList={countryListArr}
                        timeZone={timezone}
                        langList={langListArr}
                        updateComponent={() => getClient(params)}
                      />
                    ) : null}
                    <KycForm formData={clientData} updateComponent={() => getClient(params)} />
                    <ContactForm formData={clientData} updateComponent={() => getClient(params)} />
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className={styles.tabContainer}>
                    <Payments updateComponent={() => getClient(params)} />
                  </div>
                </TabPanel>
                {ENABLE_MT ? (
                  <>
                    <TabPanel>
                      <div className={styles.tabContainer}>
                        <TradingActivity />
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div className={styles.tabContainer}>
                        <OpenedPositions />
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div className={styles.tabContainer}>
                        <Deals />
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div className={styles.tabContainer}>
                        <TradingAccounts
                          status={clientData.user_profile.status}
                          initialAllowDemo={clientData.allow_demo_account}
                        />
                      </div>
                    </TabPanel>
                  </>
                ) : null}
                {PermissionListCallback ? (
                  <TabPanel>
                    <div className={styles.tabContainer}>
                      <Callbacks />
                    </div>
                  </TabPanel>
                ) : null}
                <TabPanel>
                  <div className={styles.tabContainer}>
                    <Files uuid={clientData.uuid} />
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className={styles.tabContainer}>
                    <NotesTabs
                      permission={PermissionNotesCreate}
                      updateHeadNotes={() => getClient(params)}
                    />
                  </div>
                </TabPanel>
                {PermissionViewUserLogs ? (
                  <TabPanel>
                    <div className={styles.tabContainer}>
                      <Feed />
                    </div>
                  </TabPanel>
                ) : null}
                {PermissionShowTableCryptoWalletsUserById ? (
                  <TabPanel>
                    <div className={styles.tabContainer}>
                      <CryptoWalletsTab />
                    </div>
                  </TabPanel>
                ) : null}
              </Tabs>
            </div>
          </>
        </Page>
      ) : (
        <Spinner />
      )}
    </DashboardLayout>
  );
};

export default Client;
