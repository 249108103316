import React, { useEffect, useMemo, useState } from 'react';
import Popup from 'reactjs-popup';
import { useForm, useWatch } from 'react-hook-form';
import { Col, Container, Row } from 'react-bootstrap';
import classNames from 'classnames/bind';
import styles from './payments.module.scss';
import PopupTemplate from 'components/ui/PopupTemplate/PopupTemplate';
import Button from 'components/ui/Button';
import { notify } from 'utils/notify';
import FormInput from 'components/ui/FormInput';
import { addDeposit, addDepositTransferFounds } from 'api/deposit';
import JokerSelect from 'components/ui/JokerSelect';
import { getTransactionsTypeMethods } from 'api/payments';
import PaginateSelect from 'components/ui/PaginateSelect';
import { fetchTradingAccount } from 'utils/managment/fetchData';
import { CURRENCY_OPTIONS_ENABLE_MT } from 'constants/clients/filters.const';
import { useSelector } from 'react-redux';
import { getEnableMT } from 'constants/selectors';
import { checkMtStatus } from 'utils/checkMtStatusInfo';
import { usePermission } from 'utils/usePermission';
import { AvailableForAccountWithdraw, getAvailableAccountWithdrawal } from 'api/tradingAcc';

const cx = classNames.bind(styles);

type Props = {
  triggerBtn: React.ReactElement;
  updateComponent: () => void;
  userId: string | number;
  paymentsTypeOpt: any;
};

type FormValues = {
  amount: string;
  amount_type: string;
  account_type: string;
  trading_account_id: string;
  type: string;
  method: string;
  user_id: string;
  time: string;
  to_trading_account: { trading_account: any } | string;
  from_trading_account: any;
  reference_id: string;
  tradingAccData: any;
  currency: any;
  withdraw_method: any;
};

const AddTransactionPopup = (props: Props) => {
  const { triggerBtn, updateComponent, userId, paymentsTypeOpt } = props;

  const { permissionGiven: PermissionDepositGlobal } = usePermission(
    'admin.deposit.transaction.create',
  );

  const { permissionGiven: PermissionDepositWire } = usePermission(
    'admin.deposit.transaction.create-deposit-wire',
  );
  const { permissionGiven: PermissionDepositInt } = usePermission(
    'admin.deposit.transaction.create-deposit-internal-transfer',
  );
  const { permissionGiven: PermissionDepositCrypto } = usePermission(
    'admin.deposit.transaction.create-deposit-crypto',
  );
  const { permissionGiven: PermissionDepositCard } = usePermission(
    'admin.deposit.transaction.create-deposit-credit-card',
  );
  const { permissionGiven: PermissionDepositCredit } = usePermission(
    'admin.deposit.transaction.create-deposit-credit',
  );
  const { permissionGiven: PermissionDepositBonus } = usePermission(
    'admin.deposit.transaction.create-deposit-bonus',
  );

  const { permissionGiven: PermissionWithdrawWire } = usePermission(
    'admin.deposit.transaction.create-withdraw-wire',
  );
  const { permissionGiven: PermissionWithdrawInt } = usePermission(
    'admin.deposit.transaction.create-withdraw-internal-transfer',
  );
  const { permissionGiven: PermissionWithdrawCrypto } = usePermission(
    'admin.deposit.transaction.create-withdraw-crypto',
  );
  const { permissionGiven: PermissionWithdrawCard } = usePermission(
    'admin.deposit.transaction.create-withdraw-credit-card',
  );
  const { permissionGiven: PermissionWithdrawCredit } = usePermission(
    'admin.deposit.transaction.create-withdraw-credit',
  );
  const { permissionGiven: PermissionWithdrawBonus } = usePermission(
    'admin.deposit.transaction.create-withdraw-bonus',
  );

  const { permissionGiven: PermissionTransfer } = usePermission('admin.deposit.transfer-funds');

  const typesPermissionMap: Record<string, boolean> = {
    deposit:
      PermissionDepositGlobal ||
      PermissionDepositBonus ||
      PermissionDepositCard ||
      PermissionDepositCredit ||
      PermissionDepositCrypto ||
      PermissionDepositInt ||
      PermissionDepositWire,
    withdraw:
      PermissionDepositGlobal ||
      PermissionWithdrawBonus ||
      PermissionWithdrawCard ||
      PermissionWithdrawCredit ||
      PermissionWithdrawCrypto ||
      PermissionWithdrawInt ||
      PermissionWithdrawWire,
    transfer: PermissionTransfer,
  };

  const [isLoading, setIsLoading] = useState(false);
  const [currency, setCurrency] = useState(null);
  const [paymentsMethod, setPaymentMethod] = useState({});
  const [methodsTypeOpt, setMethodsTypeOpt] = useState([]);
  const [availableForWithdraw, setAvailableForWithdraw] =
    useState<AvailableForAccountWithdraw | null>(null);
  const ENABLE_MT = useSelector(getEnableMT);
  const setDefaultValues = () => {
    return {
      amount: '',
      amount_type: '',
      account_type: '',
      trading_account_id: '',
      type: '',
      method: '',
      user_id: '',
      time: '',
      to_trading_account: '',
      from_trading_account: null,
      reference_id: '',
      tradingAccData: null,
      currency: null,
      withdraw_method: null,
    };
  };
  const {
    handleSubmit,
    control,
    reset,
    getValues,
    formState: { errors },
  } = useForm<FormValues>({
    reValidateMode: 'onChange',
    defaultValues: setDefaultValues(),
  });

  const selectedWithdrawalAcc = useWatch({ control, name: 'tradingAccData' });
  const selectedTradingAcc = useWatch({ control, name: 'from_trading_account' });
  const selectedWithdrawalMethod = useWatch({ control, name: 'withdraw_method' });

  const fetchAvailableWithdraw = async (login: string) => {
    const data = await getAvailableAccountWithdrawal(login);
    if (data) {
      setAvailableForWithdraw({ ...data.data });
    }
  };

  useEffect(() => {
    if (
      selectedWithdrawalAcc &&
      selectedWithdrawalAcc.login &&
      selectedWithdrawalAcc.source === 'WebTrader'
    ) {
      fetchAvailableWithdraw(selectedWithdrawalAcc.login);
    } else {
      setAvailableForWithdraw(null);
    }
  }, [selectedWithdrawalAcc]);

  useEffect(() => {
    if (
      selectedTradingAcc &&
      selectedTradingAcc.login &&
      selectedTradingAcc.source === 'WebTrader'
    ) {
      fetchAvailableWithdraw(selectedTradingAcc.login);
    } else {
      setAvailableForWithdraw(null);
    }
  }, [selectedTradingAcc]);

  const closeModal = (closeModal) => {
    reset(setDefaultValues());
    closeModal();
  };
  const getPaymentMethod = (key) => {
    let arr = paymentsMethod[key].map((item) => {
      return { value: item, label: item };
    });

    const selectedType = getValues().type;

    const permissionMap: Record<string, boolean> = {
      Crypto: selectedType.value === 'deposit' ? PermissionDepositCrypto : PermissionWithdrawCrypto,
      Wire: selectedType.value === 'deposit' ? PermissionDepositWire : PermissionWithdrawWire,
      internal_transfer:
        selectedType.value === 'deposit' ? PermissionDepositInt : PermissionWithdrawInt,
      Credit: selectedType.value === 'deposit' ? PermissionDepositCredit : PermissionWithdrawCredit,
      Bonus: selectedType.value === 'deposit' ? PermissionDepositBonus : PermissionWithdrawBonus,
      credit_card:
        selectedType.value === 'deposit' ? PermissionDepositCard : PermissionWithdrawCard,
    };

    if (!PermissionDepositGlobal) {
      arr = arr.filter((option) => permissionMap[option.value]);
    }

    setMethodsTypeOpt(arr);
  };
  const onOpen = () => {
    getTransactionsTypeMethods().then((res) => {
      if (res.status === 200) {
        setPaymentMethod(res.data);
      }
    });
  };
  const resetDynamicFields = () => {
    setCurrency(null);
    return {
      type: getValues('type'),
      amount: '',
      amount_type: '',
      account_type: '',
      tradingAccData: '',
      method: '',
      user_id: '',
      time: '',
      to_trading_account: '',
      from_trading_account: '',
      reference_id: '',
    };
  };
  const createTransactions = (formData, close) => {
    addDeposit(formData)
      .then((res) => {
        if (res) {
          notify({
            type: 'success',
            message: 'Added transaction successfully',
            timeOut: 3000,
          });
          setIsLoading(false);
          updateComponent();
          closeModal(close);
        }
      })
      .catch((error) => {
        checkMtStatus();
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
        setIsLoading(false);
      });
  };
  const createTransactionsTransfer = (formData, close) => {
    addDepositTransferFounds(formData)
      .then((res) => {
        if (res) {
          notify({
            type: 'success',
            message: 'Added transaction successfully',
            timeOut: 3000,
          });
          setIsLoading(false);
          setTimeout(() => updateComponent(), 2000);
          closeModal(close);
        }
      })
      .catch((error) => {
        checkMtStatus();
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
        setIsLoading(false);
      });
  };
  const onSubmit = (close, data): void => {
    const toAcc: any = getValues('to_trading_account');
    const fromAcc: any = getValues('from_trading_account');

    const isIdenticalAccounts: boolean =
      toAcc.trading_account &&
      fromAcc.trading_account &&
      fromAcc.trading_account === toAcc.trading_account;

    if (isIdenticalAccounts) {
      notify({
        type: 'error',
        message: 'It is not possible to transfer funds to the same accounts.',
        timeOut: 3000,
      });
      return;
    }

    setIsLoading(true);
    let formData = {};

    switch (data?.type?.value) {
      case 'credit_out':
      case 'withdraw':
        if (ENABLE_MT) {
          formData = {
            amount: data?.amount ?? null,
            amount_type: data?.tradingAccData?.amount_type ?? null,
            account_type: data?.tradingAccData?.amount_type ?? null,
            trading_account_id: data?.tradingAccData?.value ?? null,
            type: data?.type?.value ?? null,
            user_id: userId,
            method: data?.withdraw_method?.value,
            expiration_date: new Date(data?.time).getTime(),
          };
        } else {
          formData = {
            amount: data?.amount ?? null,
            amount_type: data?.currency?.value,
            account_type: data?.currency?.value,
            trading_account_id: data?.currency?.value,
            type: data?.type?.value ?? null,
            user_id: userId,
            method: data?.withdraw_method?.value,
            expiration_date: new Date(data?.time).getTime(),
          };
        }
        createTransactions(formData, close);
        break;
      case 'deposit':
      case 'credit_in':
        if (ENABLE_MT) {
          formData = {
            amount: data?.amount ?? null,
            amount_type: data?.tradingAccData?.amount_type ?? null,
            account_type: data?.tradingAccData?.amount_type ?? null,
            trading_account_id: data?.tradingAccData?.value ?? null,
            type: data?.type?.value ?? null,
            method: data?.method?.value,
            user_id: userId,
            expiration_date: data?.time ? new Date(data?.time).getTime() : null,
            reference_id: data?.reference_id,
          };
        } else {
          formData = {
            amount: data?.amount ?? null,
            amount_type: data?.currency?.value,
            account_type: data?.currency?.value,
            trading_account_id: data?.currency?.value,
            type: data?.type?.value ?? null,
            method: data?.method?.value,
            user_id: userId,
            expiration_date: data?.time ? new Date(data?.time).getTime() : null,
            reference_id: data?.reference_id,
          };
        }
        createTransactions(formData, close);
        break;
      case 'transfer':
        formData = {
          amount: data?.amount ?? null,
          from_trading_account: data?.from_trading_account?.value ?? null,
          to_trading_account: data?.to_trading_account?.value ?? null,
          user_id: userId,
        };
        createTransactionsTransfer(formData, close);
        break;
      default:
        break;
    }
  };

  const getMaxWithdrawalAmount = (): number | null => {
    if (!availableForWithdraw) {
      return null;
    }

    if (
      (selectedWithdrawalMethod?.value === 'Credit' ||
        selectedWithdrawalMethod?.value === 'Bonus') &&
      getValues('type')?.value != 'transfer'
    ) {
      return availableForWithdraw.available_credit_amount;
    } else {
      return availableForWithdraw.available_balance_amount;
    }
  };

  const maxWithdraw = useMemo(
    () => getMaxWithdrawalAmount(),
    [selectedWithdrawalMethod, availableForWithdraw],
  );

  const amountValidationWT = maxWithdraw
    ? {
        validationHintMessage: `The value must be less than or equal to ${maxWithdraw}`,
        customValidityFunc: (v: string) => Number(v) > maxWithdraw || Number(v) < 0.01,
      }
    : {};

  const renderFields = (type) => {
    switch (type?.value) {
      // case 'credit_out': {
      //   return (
      //     <>
      //       <Col md={6}>
      //         <PaginateSelect
      //           isMulti={false}
      //           isRequired={true}
      //           isSearchable={false}
      //           selectedAcc={true}
      //           label="From account"
      //           control={control}
      //           id="tradingAccData"
      //           name="tradingAccData"
      //           onChange={(page, search) => fetchTradingAccount(page, search, userId)}
      //           onSelectChange={(v) => {
      //             setCurrency(v.amount_type);
      //           }}
      //           rules={{ required: ' Field is required' }}
      //           errors={errors.tradingAccData}
      //         />
      //       </Col>
      //       <Col md={6}>
      //         <FormInput
      //           name="amount"
      //           type="number"
      //           placeholder="0.00"
      //           step="0.01"
      //           label="Amount "
      //           control={control}
      //           rules={{ required: ' Field is required' }}
      //           errors={errors.type}
      //           additional={currency}
      //           min={0}
      //         />
      //       </Col>
      //     </>
      //   );
      // }
      // case 'credit_in': {
      //   return (
      //     <>
      //       <Col md={6}>
      //         <Select
      //           name="method"
      //           placeholder="Choose payment method"
      //           label="Payment method"
      //           control={control}
      //           rules={{ required: ' Field is required' }}
      //           errors={errors.method}
      //           options={methodsTypeOpt}
      //         />
      //       </Col>
      //       <Col md={6}>
      //         <PaginateSelect
      //           isMulti={false}
      //           isRequired={true}
      //           isSearchable={false}
      //           selectedAcc={true}
      //           label="To account"
      //           control={control}
      //           id="tradingAccData"
      //           name="tradingAccData"
      //           onChange={(page, search) => fetchTradingAccount(page, search, userId)}
      //           onSelectChange={(v) => {
      //             setCurrency(v.amount_type);
      //           }}
      //           rules={{ required: ' Field is required' }}
      //           errors={errors.tradingAccData}
      //         />
      //       </Col>
      //       <Col md={6}>
      //         <FormInput
      //           name="amount"
      //           type="number"
      //           placeholder="0.00"
      //           step="0.01"
      //           label="Amount"
      //           control={control}
      //           rules={{ required: ' Field is required' }}
      //           errors={errors.type}
      //           additional={currency}
      //           min={0}
      //         />
      //       </Col>
      //       <Col md={6}>
      //         <DatePicker
      //           name="time"
      //           control={control}
      //           label="Expiration date & time"
      //           dateFormat="MMMM d, yyyy h:mm aa"
      //           showTimeInput
      //         />
      //       </Col>
      //     </>
      //   );
      // }
      case 'deposit': {
        return (
          <>
            <Col md={6} className="mb-3">
              <JokerSelect
                isRequired
                name="method"
                placeholder="Choose payment method"
                label="Payment method"
                control={control}
                rules={{ required: 'Field is required' }}
                errors={errors.method}
                options={methodsTypeOpt}
              />
            </Col>
            <Col md={6} className="mb-3">
              {ENABLE_MT ? (
                <PaginateSelect
                  isMulti={false}
                  isRequired={true}
                  isSearchable={false}
                  selectedAcc={true}
                  label="To account"
                  control={control}
                  id="tradingAccData"
                  name="tradingAccData"
                  onChange={(page, search) => fetchTradingAccount(page, search, userId)}
                  onSelectChange={(v) => {
                    setCurrency(v.amount_type);
                  }}
                  rules={{ required: 'Field is required' }}
                  errors={errors.tradingAccData}
                />
              ) : (
                <JokerSelect
                  label="Currency"
                  name="currency"
                  placeholder="Currency"
                  control={control}
                  rules={{ required: 'Field is required' }}
                  errors={errors.currency}
                  options={CURRENCY_OPTIONS_ENABLE_MT}
                />
              )}
            </Col>
            <Col md={6} className="mb-3">
              <FormInput
                name="amount"
                type="number"
                placeholder="0.00"
                label="Amount"
                step="0.01"
                control={control}
                rules={{ required: 'Field is required' }}
                errors={errors.amount}
                additional={currency}
                min={0}
              />
            </Col>
            <Col md={6} className="mb-3">
              <FormInput
                name="reference_id"
                type="string"
                maxLength={255}
                placeholder="External Reference ID"
                label="External Reference ID"
                control={control}
                errors={errors.reference_id}
              />
            </Col>
          </>
        );
      }
      case 'withdraw': {
        return (
          <>
            <Col md={6} className="mb-3">
              <JokerSelect
                isRequired
                name="withdraw_method"
                placeholder="Choose payment method"
                label="Payment method"
                control={control}
                rules={{ required: 'Field is required' }}
                errors={errors.withdraw_method}
                options={methodsTypeOpt}
              />
            </Col>
            <Col md={6} className="mb-3">
              {ENABLE_MT ? (
                <PaginateSelect
                  isMulti={false}
                  isRequired={true}
                  isSearchable={false}
                  selectedAcc={true}
                  label="From account"
                  control={control}
                  id="tradingAccData"
                  name="tradingAccData"
                  rules={{ required: 'Field is required' }}
                  errors={errors.tradingAccData}
                  onChange={(page, search) => fetchTradingAccount(page, search, userId)}
                  onSelectChange={(v) => {
                    setCurrency(v.amount_type);
                  }}
                />
              ) : (
                <JokerSelect
                  label="Currency"
                  name="currency"
                  placeholder="Currency"
                  control={control}
                  rules={{ required: 'Field is required' }}
                  errors={errors.currency}
                  options={CURRENCY_OPTIONS_ENABLE_MT}
                />
              )}
            </Col>
            <Col md={6} className="mb-3">
              <FormInput
                name="amount"
                type="number"
                placeholder="0.00"
                label="Amount"
                step="0.01"
                control={control}
                rules={{ required: 'Field is required' }}
                errors={errors.amount}
                additional={currency}
                min="0.01"
                max={maxWithdraw}
                disabled={!maxWithdraw && !ENABLE_MT}
                details={maxWithdraw ? `Available: ${maxWithdraw}` : undefined}
                {...amountValidationWT}
              />
            </Col>
          </>
        );
      }
      case 'transfer': {
        return (
          <>
            <Col md={6} className="mb-3">
              <PaginateSelect
                isMulti={false}
                isRequired={true}
                isSearchable={false}
                selectedAcc={true}
                label="From account"
                control={control}
                rules={{ required: 'Field is required' }}
                errors={errors.from_trading_account}
                id="from_trading_account"
                name="from_trading_account"
                onChange={(page, search) => fetchTradingAccount(page, search, userId)}
                onSelectChange={(v) => {
                  setCurrency(v.amount_type);
                }}
              />
            </Col>
            <Col md={6} className="mb-3">
              <PaginateSelect
                isMulti={false}
                isRequired={true}
                isSearchable={false}
                selectedAcc={true}
                label="To account"
                control={control}
                rules={{ required: 'Field is required' }}
                errors={errors.to_trading_account}
                id="to_trading_account"
                name="to_trading_account"
                onChange={(page, search) => fetchTradingAccount(page, search, userId)}
              />
            </Col>
            <Col md={6} className="mb-3">
              <FormInput
                name="amount"
                type="number"
                placeholder="0.00"
                label="Amount"
                step="0.01"
                control={control}
                rules={{ required: 'Field is required' }}
                errors={errors.amount}
                additional={currency}
                min="0.01"
                max={maxWithdraw}
                disabled={!maxWithdraw && !ENABLE_MT}
                details={maxWithdraw ? `Available: ${maxWithdraw}` : undefined}
                {...amountValidationWT}
              />
            </Col>
          </>
        );
      }
    }
  };

  return (
    <>
      <Popup
        modal
        trigger={triggerBtn}
        closeOnEscape
        repositionOnResize
        lockScroll
        closeOnDocumentClick
        onOpen={onOpen}
      >
        {(close) => (
          <PopupTemplate
            trigger={<button> Trigger</button>}
            dismissModal={closeModal.bind(undefined, close)}
            headerTitle={'New transaction'}
            rightContent={
              <div className={cx('content')}>
                <div className={cx('content-form', 'addNewTransactionForm')}>
                  <form onSubmit={handleSubmit(onSubmit.bind(undefined, close))}>
                    <Container>
                      <Row>
                        <Col md={12} className="mb-3">
                          <JokerSelect
                            isRequired
                            name="type"
                            placeholder="Payment type"
                            label="Payment type"
                            control={control}
                            rules={{ required: 'Field is required' }}
                            errors={errors.type}
                            options={paymentsTypeOpt.filter(
                              (type) => typesPermissionMap[type.value],
                            )}
                            onSelectChange={(e) => {
                              getPaymentMethod(getValues('type').value);
                              reset(resetDynamicFields());
                              // reset(setDefaultValues());
                            }}
                          />
                        </Col>
                        {renderFields(getValues('type'))}
                      </Row>
                      <Row>
                        <Col md={12}>
                          <div className={cx('content-controls')}>
                            <div className={cx('controls__buttons')}>
                              <div className={cx('note')}>
                                <b>Note:</b> This action can not be undone!
                              </div>
                              <div className={styles.wrapperBtn}>
                                <Button
                                  buttonText="Cancel"
                                  buttonType="outline"
                                  type="button"
                                  onClick={closeModal.bind(undefined, close)}
                                />
                                <Button
                                  isLoading={isLoading}
                                  buttonText="Confirm"
                                  type="submit"
                                  onClick={(data) => handleSubmit(onSubmit.bind(data, close))}
                                />
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </form>
                </div>
              </div>
            }
          />
        )}
      </Popup>
    </>
  );
};
export default AddTransactionPopup;
