import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames/bind';
import styles from './../../client-page.module.scss';

import TablePagination from 'components/ui/TablePagination';
import { useParams } from 'react-router-dom';
import TradingActivityTable from 'components/ui/Table/TradingActivityTable';
import TradingActivityFilter from './TradingActivityFilter';
import { findTradingHistory } from 'api/tradingHistory';
import { usePermission } from 'utils/usePermission';
import { checkMtStatus } from 'utils/checkMtStatusInfo';
import { useTradingActivity } from './useTradingActivity';
import { FormProvider } from 'react-hook-form';
import { TradingActivityFields } from './TradingActivityFields/TradingActivityFields';
import { TableLayout } from 'components/ui/Table/TableLayout';

const cx = classNames.bind(styles);

const TradingActivity = () => {
  const { permissionGiven: PermissionViewTradingHistory } = usePermission(
    'admin.trading-account.history.view',
  );

  const [tradingHistoryList, setTradingHistoryList] = useState([]);
  const [isListLoading, setIsListLoading] = useState(false);
  const [filters, setFilters] = useState(null);
  const [tableMeta, setTableMeta] = useState(undefined);
  const [tableLinks, setTableLinks] = useState(undefined);
  const [perPageCount, setPerPageCount] = useState(10);
  const [tickerOpt, setTickerOpt] = useState([]);
  const [typeOpt, setTypeOpt] = useState([]);
  const [queryMessage, setQueryMessage] = useState('');

  const [sort, setSort] = useState();
  const { id } = useParams();

  const componentMounted = useRef(false);

  useEffect(() => {
    if (PermissionViewTradingHistory) {
      getTradingHistory();
      componentMounted.current = true;
    }

    return () => {
      componentMounted.current = false;
    };
  }, [PermissionViewTradingHistory]);

  const getTradingHistory = (pageParams?, filtersData?) => {
    setIsListLoading(true);

    findTradingHistory(getTablePageParams(pageParams), filtersData, id)
      .then((res) => {
        if (componentMounted.current) {
          setTradingHistoryList(res.data.data);
          setTableMeta(res.data.meta);
          setTableLinks(res.data.links);
          setPerPageCount(res.data.meta.per_page);
          setQueryMessage(res.data?.message);

          const optTradeSide = res.data.trade_side.map((opt) => ({
            value: opt,
            label: opt,
          }));
          setTypeOpt(optTradeSide);

          const optTickerOpt = res.data.ticker.map((opt) => ({
            value: opt,
            label: opt,
          }));
          setTickerOpt(optTickerOpt);
        }
      })
      .catch((err) => {
        console.log(err);
        checkMtStatus();
      })
      .finally(() => setIsListLoading(false));
  };

  const getTablePageParams = (pageParams) => {
    if (!pageParams) {
      return '';
    }

    const { page, navigate, perPage } = pageParams;

    let perPageParams = `per_page=${perPage || perPageCount}`;
    let toPage;

    if (page) {
      toPage = `page=${page}`;
    } else if (navigate && tableLinks) {
      const splittedLink = tableLinks[navigate].split('?').reverse()[0];
      const splitedParams = splittedLink.split('&');
      toPage = splitedParams.filter((item) => item.includes('page='))?.[0];
    }

    return `${toPage}&${perPageParams}`;
  };

  const getSortFiltersForPageNavigation = () => {
    if (sort && filters) {
      return {
        sort,
        ...filters,
      };
    }

    if (sort && !filters) {
      return {
        sort,
      };
    }

    if (!sort && filters) {
      return filters;
    }
  };

  const clearFiltersSortList = () => {
    setPerPageCount(10);
    getTradingHistory({ navigate: 'first', perPage: 10 });
    setFilters(null);
  };

  const { form, table } = useTradingActivity();

  return (
    <>
      <div className={styles.tabTitle}>Trading activity</div>
      <FormProvider {...form}>
        <TradingActivityFields userId={id} tickerOpt={tickerOpt} typeOpt={typeOpt} />
        <TableLayout
          header={
            <TradingActivityFilter
              onSubmit={(filtersData) => {
                if (!filters && !filtersData) {
                  return;
                }
                if (filters && !filtersData) {
                  clearFiltersSortList();
                } else {
                  setFilters(filtersData);
                  setPerPageCount(filtersData?.filter?.limit);
                  getTradingHistory(
                    { navigate: 'first', perPage: filtersData?.filter?.limit || 10 },
                    filtersData,
                  );
                }
              }}
              reloadFilters={isListLoading}
              queryMessage={queryMessage}
              setDefaultColumnsOrder={table.setDefaultColumnsOrder}
            />
          }
        >
          <TradingActivityTable
            data={tradingHistoryList}
            perPage={perPageCount}
            showLoader={isListLoading}
            modifyDataHandler={(callback) => console.log('a')}
            columns={table.columns}
            saveColumnOrder={table.saveColumnOrder}
          />
        </TableLayout>
      </FormProvider>

      {tradingHistoryList?.length ? (
        <TablePagination
          currentPage={tableMeta?.current_page}
          pagesLength={tableMeta?.last_page}
          perPageChange={(value) => {
            setPerPageCount(value);
            getTradingHistory({ perPage: value }, getSortFiltersForPageNavigation());
          }}
          goToSelectedPage={(page) => {
            getTradingHistory({ page }, getSortFiltersForPageNavigation());
          }}
          hidePageSelect={true}
        />
      ) : null}
    </>
  );
};

export default TradingActivity;
