import { useEffect, useState } from 'react';
import { DashboardLayout } from 'pages/UserView/DashboardLayout';
import { usePageTitle } from 'utils/usePageTitle';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { LogosTab, ColorsTab } from './tabs';
import { getStyleListMethod } from 'api/settingsLK';
import Page from 'pages/Page';

import styles from './styles-old-page.module.scss';

const title = 'Style of the system';

/** стр. для старого ЛК */
const StylesOldPage = () => {
  const [data, setData] = useState({});

  usePageTitle(title);

  const getStyles = () => {
    getStyleListMethod()
      .then((response) => {
        setData(response.data.data);
      })
      .catch(console.log);
  };

  useEffect(() => {
    getStyles();
  }, []);

  return (
    <DashboardLayout>
      <Page title="Style of the system">
        <Tabs>
          <TabList>
            <Tab>Colors</Tab>
            <Tab>Logos</Tab>
          </TabList>
          <TabPanel>
            <div className={styles.tabContainer}>
              <ColorsTab data={data} updateComponent={getStyles} />
            </div>
          </TabPanel>
          <TabPanel>
            <div className={styles.tabContainer}>
              <LogosTab styles={data} updateComponent={getStyles} />
            </div>
          </TabPanel>
        </Tabs>
      </Page>
    </DashboardLayout>
  );
};

export default StylesOldPage;
