import { Client } from 'models/Clients/Clients';
import { Col, Row } from 'react-bootstrap';
import { Card } from 'components/ui/Card';
import cn from 'classnames';
import styles from './client-info.module.scss';
import { Icon } from 'components/ui/Icon';
import { BalancesData } from 'models/Balances';
import { ClientRegistered } from './blocks/registered/ClientRegistered';
import { ClientAccountStatus } from './blocks/status/ClientAccountStatus';
import { ClientAffiliate } from './blocks/affiliate/ClientAffiliate';
import { ClientLastActivity } from './blocks/lastActivity/ClientLastActivity';
import { ClientLastLogin } from './blocks/lastLogin/ClientLastLogin';
import { ClientBalance } from './blocks/balance/ClientBalance';
import { ClientPersonalInfo } from './blocks/personalInfo/ClientPersonalInfo';
import { ClientPinnedNotes } from './blocks/pinnedNotes/ClientPinnedNotes';
import { ClientAcquisitionStatus } from './blocks/acquisitionStatus/ClientAcquisitionStatus';
import { ClientLastIps } from './blocks/lastIps/ClientLastIps';

type Props = {
  client: Client;
  balance: BalancesData;
  fetchBalance: (params: any, period?: string, refreshProfit?: boolean) => Promise<void>;
  updateCallback: () => void;
};

export const ClientInfo = ({ client, balance, fetchBalance, updateCallback }: Props) => {
  return (
    <Row className={cn(styles.container, 'row-gap-4')}>
      <Row className={cn(styles.meta, 'row-gap-4')}>
        <Col lg={2} md={4} xs={6}>
          <Card className={styles.card} title="Account status">
            <ClientAccountStatus client={client} updateCallback={updateCallback} />
          </Card>
        </Col>
        <Col lg={2} md={4} xs={6}>
          <Card className={styles.card} title="Total balance">
            <ClientBalance balance={balance} fetchBalance={fetchBalance} />
          </Card>
        </Col>
        <Col lg={2} md={4} xs={6}>
          <Card className={styles.card} title="Affiliate">
            <ClientAffiliate client={client} />
          </Card>
        </Col>
        <Col lg={2} md={4} xs={6}>
          <Card className={styles.card} title="Last activity">
            <ClientLastActivity client={client} />
          </Card>
        </Col>
        <Col lg={2} md={4} xs={6}>
          <Card className={styles.card} title="Registered">
            <ClientRegistered date={client.registration} />
          </Card>
        </Col>
        <Col lg={2} md={4} xs={6}>
          <Card className={styles.card} title="Last login">
            <ClientLastLogin client={client} />
          </Card>
        </Col>
      </Row>
      <Row className="row-gap-4">
        <Col xxl={4} xl={6} lg={6} md={8} xs={12}>
          <Card
            className={cn(styles.card, styles.personalInfo)}
            titleClassName={styles.personalInfoTitle}
            title="Personal information"
            iconTitle={<Icon name="personalInfo" color="var(--main-color)" />}
          >
            <ClientPersonalInfo client={client} />
          </Card>
        </Col>
        <Col xxl={2} xl={3} lg={3} md={4} xs={6}>
          <Card className={styles.card} title="Acquisition status">
            <ClientAcquisitionStatus client={client} updateCallback={updateCallback} />
          </Card>
        </Col>
        <Col xxl={2} xl={3} lg={3} md={4} xs={6}>
          <Card className={styles.card} title="Last 10 IP's">
            <ClientLastIps client={client} />
          </Card>
        </Col>
        <Col xxl={4} xl={12} lg={12} md={8} xs={12}>
          <Card className={cn(styles.card, styles.notes)} title="Pinned notes">
            <ClientPinnedNotes client={client} />
          </Card>
        </Col>
      </Row>
    </Row>
  );
};
