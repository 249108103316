import { findAllCallbacksMethod } from 'api/callbaks';
import classNames from 'classnames/bind';
import CallbacksTableAdmin from 'components/ui/Table/CallbacksTableAdmin';

import TablePagination from 'components/ui/TablePagination';
import Page from 'pages/Page/Page';
import { DashboardLayout } from 'pages/UserView/DashboardLayout';
import React, { useEffect, useRef, useState } from 'react';

import { useParams } from 'react-router-dom';
import { notify } from 'utils/notify';
import { usePermission } from '../../utils/usePermission.tsx';
import CallbacksFilter from './CallbacksFilter/CallbacksFilter';
import styles from './page-style.module.scss';
import { getTablePageParams } from '../../utils/getTablePageParams.js';
import { Callback } from 'models/Callbacks/Callback.js';
import { CallbackFilter } from 'models/Callbacks/CallbackFilter.js';
import { TableLinks, TableMeta } from 'models/Table.js';
import { useAppDispatch } from '../../store';
import { toggleScrollToTop } from '../../store/slice/tableSlice.ts';
import { useCheckAutoRefresh } from '../../utils/useCheckAutoRefresh.tsx';
import { usePageTitle } from 'utils/usePageTitle.ts';
import { FormProvider } from 'react-hook-form';
import { TableLayout } from 'components/ui/Table/TableLayout/TableLayout.tsx';
import { useCallbacksPage } from './useCallbacksPage.ts';
import { CallbacksFields } from './CallbacksFields/CallbacksFields.tsx';

const cx = classNames.bind(styles);

const title = 'Callbacks';

const CallbacksPage = () => {
  const { permissionGiven: PermissionList } = usePermission('admin.callback.index');

  const dispatch = useAppDispatch();

  const [callbacksList, setCallbacksList] = useState<Callback[]>([]);
  const [isListLoading, setIsListLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState(null);
  const [filters, setFilters] = useState<CallbackFilter>(null);
  const [tableMeta, setTableMeta] = useState<TableMeta>();
  const [tableLinks, setTableLinks] = useState<TableLinks>();
  const [perPageCount, setPerPageCount] = useState(10);
  const [queryMessage, setQueryMessage] = useState('');

  const { id } = useParams();
  const checkAutoRefresh = useCheckAutoRefresh('Page Callbacks');

  usePageTitle(title);

  const componentMounted = useRef(false);

  const fetchCallbaksList = (options?, data?) => {
    setIsListLoading(true);

    findAllCallbacksMethod(getTablePageParams(options, perPageCount, tableLinks), data)
      .then((res) => {
        if (res.status === 200) {
          setCallbacksList(res.data.data);
          setTableMeta(res.data.meta);
          setTableLinks(res.data.links);
          setQueryMessage(res.data.message);
        }
      })
      .catch((error) => {
        if (error && error?.response?.data !== null) {
          notify({
            type: 'error',
            message: error.response,
            timeOut: 3000,
          });
        }
      })
      .finally(() => {
        setIsListLoading(false);
      });
  };

  useEffect(() => {
    if (PermissionList) {
      componentMounted.current = true;
      fetchCallbaksList();
    }

    return () => {
      componentMounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PermissionList]);

  const clearFiltersSortList = () => {
    setPerPageCount(10);
    fetchCallbaksList({ navigate: 'first', perPage: 10 });
    setFilters(null);
    // setSort(null);
  };

  const { form, table } = useCallbacksPage();

  return (
    <>
      <DashboardLayout>
        <Page isStickyTitle title={`${tableMeta?.total} Callbacks`}>
          <>
            <FormProvider {...form}>
              <CallbacksFields />
              <TableLayout
                header={
                  <CallbacksFilter
                    onSubmit={(filtersData: CallbackFilter) => {
                      if (!filters && !filtersData) {
                        return;
                      }
                      if (filters && !filtersData) {
                        clearFiltersSortList();
                      } else {
                        setFilters(filtersData);
                        setPerPageCount(filtersData?.filter?.limit || 10);
                        fetchCallbaksList(
                          { navigate: 'first', perPage: perPageCount },
                          filtersData,
                        );
                      }
                    }}
                    reloadFilters={isListLoading}
                    queryMessage={queryMessage}
                    setDefaultColumnsOrder={table.setDefaultColumnsOrder}
                  />
                }
              >
                {PermissionList ? (
                  <CallbacksTableAdmin
                    data={callbacksList}
                    perPage={tableMeta?.per_page}
                    showLoader={isListLoading}
                    onRowSelect={setSelectedRows}
                    userId={id}
                    columns={table.columns}
                    saveColumnOrder={table.saveColumnOrder}
                  />
                ) : null}
              </TableLayout>
            </FormProvider>
            {PermissionList && callbacksList?.length ? (
              <TablePagination
                currentPage={tableMeta?.current_page}
                pagesLength={tableMeta?.last_page}
                perPageChange={(value) => {
                  setPerPageCount(value);
                  fetchCallbaksList({ perPage: value }, filters);
                  dispatch(toggleScrollToTop());
                }}
                goToSelectedPage={(page) => {
                  fetchCallbaksList({ page, perPage: perPageCount }, filters);
                  dispatch(toggleScrollToTop());
                }}
              />
            ) : null}
          </>
        </Page>
      </DashboardLayout>
    </>
  );
};

export default CallbacksPage;
